import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension, Padding } from "@superblocksteam/shared";
import React, {
  useContext,
  useEffect,
  RefObject,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { DragLayerMonitor, useDragLayer, XYCoord } from "react-dnd";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useAnimationThrottle } from "hooks/ui";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";
import { shouldScrollIntoEmptySpace } from "legacy/selectors/dropTargetSelectors";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { getSelectedWidgets } from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import { scaledXYCoord } from "../../../utils/size";
import { getResponsiveCanvasScaleFactor } from "../../selectors/applicationSelectors";
import { useGetSelectedStackDragPositions } from "../StackLayout/hooks";
import { DropTargetContext } from "./DropTargetUtils";
import { currentDropRow, findFreePosition } from "./ResizableUtils";
import WidgetDragPreview, { getNewWidgetDragOffset } from "./WidgetDragPreview";

const sizeCalc = (
  dim1?: Dimension<"px">,
  dim2?: Dimension<"px">,
  extraPx = 0,
) => {
  const padd = (dim1?.value ?? 0) + (dim2?.value ?? 0);
  const extra = padd >= extraPx ? extraPx : padd;
  return `calc(100% - ${padd}px + ${extra}px)`;
};

const WrappedDragLayer = styled.div<{ $padding?: Padding }>`
  position: absolute;
  pointer-events: none;
  cursor: pointer;

  left: ${(props) => props.$padding?.left?.value ?? 0}${(props) => props.$padding?.left?.mode ?? "px"};
  top: ${(props) => props.$padding?.top?.value ?? 0}${(props) => props.$padding?.top?.mode ?? "px"};

  /* Extra 4px for container padding and border */
  height: ${(props) =>
    sizeCalc(props.$padding?.top, props.$padding?.bottom, 4)};
  width: ${(props) => sizeCalc(props.$padding?.left, props.$padding?.right, 4)};
`;

// Height & width are set using inline styles for performance
const DotGridLayer = styled.div`
  position: absolute;
  pointer-events: none;
  left: -1px;
  top: -1px;
  height: 100%;
  width: 100%;
  // animate the opacity to 1
  transition: opacity 0.1s ease-in-out;

  background-image: radial-gradient(
    circle at 1px 1px,
    rgba(128, 128, 128, 0.3) 1px,
    transparent 0
  );
`;

const DEFAULT_EXTEND_CANVAS_TIMEOUT_MS = 1000;

type DragLayerProps = {
  parentRowHeight: number;
  canDropTargetExtend?: boolean;
  parentColumnWidth: number;
  visible: boolean;
  occupiedSpaces?: OccupiedSpace[];
  onBoundsUpdate: (rect: DOMRect) => void;
  isOver: boolean;
  isAllowedType: boolean;
  maxRows: number;
  minRows: number;
  parentCols: number;
  isResizing?: boolean;
  parentWidgetId: string;
  force: boolean;
  padding?: Padding;
  delayExtension?: boolean;
};

const DragLayerComponent = (props: DragLayerProps) => {
  const { updateDropTargetRows } = useContext(DropTargetContext);
  const dropTargetMask: RefObject<HTMLDivElement> = React.useRef(null);
  const dragLayerRef: RefObject<HTMLDivElement> = React.useRef(null);
  const widgetsDragPreviewRef = React.useRef<HTMLDivElement>(null);
  const parentWidget = useAppSelector((state) => {
    return props.parentWidgetId
      ? getFlattenedCanvasWidget(state, props.parentWidgetId)
      : undefined;
  });
  const canvasScaleFactor = useAppSelector(getResponsiveCanvasScaleFactor);
  const selectedWidgets = useAppSelector(getSelectedWidgets);

  const dropTargetOffset = useRef<XYCoord | undefined>();
  const lastRef: React.MutableRefObject<{
    isDragging: boolean;
    currentOffset: false | { x: number; y: number };
    availablePosition: any;
    widget: any;
    mouseOffset: XYCoord | null;
  } | null> = useRef(null);

  const throttledUpdateWrapper = useCallback((monitor: DragLayerMonitor) => {
    throttledInternal.current(monitor);
  }, []);
  const throttledUpdate = useAnimationThrottle(throttledUpdateWrapper);
  const collected = useDragLayer((monitor) => {
    if (!monitor.isDragging()) {
      return {
        isDragging: false,
        currentOffset: false as const,
        widget: undefined,
        availablePosition: false,
      };
    }
    throttledUpdate?.(monitor);
    return lastRef.current;
  });

  const { isDragging, currentOffset, widget, availablePosition } =
    collected ?? {};

  const isNewWidget = widget !== undefined && widget?.widgetName === undefined;

  const getSelectedStackDragPositions = useGetSelectedStackDragPositions();
  const stackDragPositions = useMemo(() => {
    return widget
      ? getSelectedStackDragPositions(
          widget.widgetId,
          parentWidget?.parentColumnSpace,
        )
      : undefined;
  }, [getSelectedStackDragPositions, widget, parentWidget?.parentColumnSpace]);

  const throttledInternal = useRef<any>();
  throttledInternal.current = (monitor: DragLayerMonitor) => {
    if (!monitor.isDragging() || !dropTargetOffset.current) {
      if (!lastRef.current || lastRef.current.isDragging) {
        lastRef.current = {
          isDragging: false,
          currentOffset: false,
          widget: undefined,
          availablePosition: false,
          mouseOffset: null,
        };
      }
      // Important: Skip expensive calculation when not dragging
      return;
    }

    const widgets = [
      monitor.getItem(),
      ...selectedWidgets.filter(
        (w) => w.widgetId !== monitor.getItem()?.widgetId,
      ),
    ];

    const sourceClientOffset = monitor.getSourceClientOffset() as XYCoord;

    // If a new widget, make sure we put the cursor during the drag set into the component bounds a bit so
    // it's not right on the edge of the component in the top left corner
    if (isNewWidget) {
      const newWidgetDragOffset = getNewWidgetDragOffset(
        props.parentColumnWidth,
      );
      sourceClientOffset.x -= newWidgetDragOffset.x;
      sourceClientOffset.y -= newWidgetDragOffset.y;
    }
    const mouseOffset = scaledXYCoord(
      monitor.getClientOffset() as XYCoord,
      canvasScaleFactor,
    );

    const result = {
      isDragging: monitor.isDragging(),
      currentOffset: scaledXYCoord(sourceClientOffset, canvasScaleFactor),
      mouseOffset,
      widget: monitor.getItem(),
      availablePosition: findFreePosition({
        clientOffset: scaledXYCoord(sourceClientOffset, canvasScaleFactor),
        colWidth: props.parentColumnWidth,
        rowHeight: props.parentRowHeight,
        widgets,
        dropTargetOffset: dropTargetOffset.current,
        occupiedSpaces: props.occupiedSpaces,
        parentRows: props.maxRows,
        parentCols: props.parentCols,
        mouseOffset,
        stackDragPositions,
      }),
    };
    // This maintains referential equality unless there's been a change.
    // The most commonly updated property is availablePosition
    if (!equal(lastRef.current, result)) {
      lastRef.current = result;
    }
  };

  // To make it so that users don't automatically extend right away which would make it near impossible drag from one section column
  // into another below, we use a small delay before extending the canvas. The flow is:
  // 1. User drags to bottom and triggers small timeout that must finish before extension occurs
  // 2. Once timeout is done, if the user is still dragging over that area, every extension happens instantly for the duration of the drag interaction
  // 3. Once the user lets go and the drag interaction ends (after possibly having extended the canvas) the requirement of the timeout is added back, so the next attempt top extend requires first hitting the timeout delay again
  const extendTimeoutRef = useRef<number | undefined>(undefined);
  const extendTimeoutCompleted = useRef<boolean>(false);

  // If the canvas we're dragging over changes, reset the timeout
  useEffect(() => {
    window.clearTimeout(extendTimeoutRef.current);
    extendTimeoutRef.current = undefined;
    extendTimeoutCompleted.current = false;
  }, [props.isOver, props.visible, isDragging]);

  const updateDropTargetRowsIfNecessary = useCallback(
    (localOffset: { x: number; y: number }, maxExtraRowsAllowed?: number) => {
      if (!dropTargetOffset.current) return;
      const currentRow = currentDropRow(
        props.parentRowHeight,
        dropTargetOffset.current.y,
        localOffset.y,
        widget,
      );

      let rows = currentRow;

      if (maxExtraRowsAllowed) {
        rows = Math.min(currentRow, props.minRows + maxExtraRowsAllowed);
      }

      updateDropTargetRows?.(widget.widgetId, rows);
    },

    [props.parentRowHeight, widget, updateDropTargetRows, props.minRows],
  );

  const scrollIntoEmptySpace = useAppSelector((state: AppState) =>
    shouldScrollIntoEmptySpace(state, props.parentWidgetId),
  );

  // We need to use an ref because offset can be used in the timeout
  const currentOffsetRef = useRef(currentOffset);
  currentOffsetRef.current = currentOffset;

  const dragLayerBoundingRectRef = useRef<DOMRect | undefined>(undefined);
  useEffect(() => {
    if (isDragging) {
      dragLayerBoundingRectRef.current =
        dragLayerRef.current?.getBoundingClientRect();
    }
  }, [isDragging]);

  useEffect(() => {
    const offset = currentOffsetRef.current;
    if (!offset || !dropTargetOffset.current) return;

    const mouseOffset = lastRef.current?.mouseOffset;

    // if scrollIntoEmptySpace is true, it mean current drop target is the bottom canvas in the main canvas
    // or the slideout or modal canvas
    // if mouse if not over the bottom canvas and mouse is below the top of the bottom canvas, then the mouse is
    // also below the bottom of bottom canvas and we should extend canvas into empty space (e.g. when you drop first widget into
    //  white space below the section in the new app)

    const shouldExtendIntoEmptySpace =
      !props.isOver &&
      scrollIntoEmptySpace &&
      mouseOffset &&
      dragLayerBoundingRectRef.current !== undefined &&
      mouseOffset.y > dragLayerBoundingRectRef.current.top;

    // in two cases we need to extend current canvas:
    // 1. user is dragging over current canvas
    // 2. current canvas is bottom canvas and user is dragging below the bottom canvas
    const isOverOrLastItem = props.isOver || shouldExtendIntoEmptySpace;

    // but we dont want to do it unless the user's drag is somewhere below the canvas since stacks
    // with align-bottom may behave unexpectedly if we do so.
    const isHorizontallyBoundedByColumn =
      mouseOffset &&
      dragLayerBoundingRectRef.current &&
      mouseOffset.x > dragLayerBoundingRectRef.current?.left &&
      mouseOffset.x < dragLayerBoundingRectRef.current?.right;

    if (
      isOverOrLastItem &&
      props.canDropTargetExtend &&
      isDragging &&
      isHorizontallyBoundedByColumn
    ) {
      const row = currentDropRow(
        props.parentRowHeight,
        dropTargetOffset.current.y,
        offset.y,
        widget,
      );
      // No need to extend if we're below the minimum rows
      if (row <= props.minRows) {
        // Clear the timeout if we're under the minimum rows
        // because the the user has dragged away from the edge
        // so they don't get "stuck" in expand mode
        window.clearTimeout(extendTimeoutRef.current);
        extendTimeoutCompleted.current = false;
        extendTimeoutRef.current = undefined;
        return;
      }
      if (extendTimeoutCompleted.current || !props.delayExtension) {
        // The timeout has completed, so we can extend the canvas now
        updateDropTargetRowsIfNecessary(offset);
      } else if (!extendTimeoutCompleted.current) {
        // If the timeout hasn't completed, we only want to extend the canvas
        // up to a small amount above the current height, once the timeout completes
        // we can extend to the full amount
        updateDropTargetRowsIfNecessary(
          offset,
          GridDefaults.CANVAS_EXTENSION_INITIAL_LIMIT,
        );

        if (!extendTimeoutRef.current) {
          // If the timeout hasn't even been created, create it
          extendTimeoutRef.current = window.setTimeout(
            () => {
              const offset = currentOffsetRef.current;
              extendTimeoutCompleted.current = true;
              if (offset) {
                updateDropTargetRowsIfNecessary(offset);
              }
            },
            props.delayExtension ? DEFAULT_EXTEND_CANVAS_TIMEOUT_MS : 0,
          );
        }
      }
    }
  }, [
    dragLayerBoundingRectRef,
    updateDropTargetRowsIfNecessary,
    props.delayExtension,
    parentWidget,
    isDragging,
    widget,
    props.isOver,
    props.canDropTargetExtend,
    props.parentRowHeight,
    props.minRows,
    scrollIntoEmptySpace,
    props.parentWidgetId,
    currentOffset,
  ]);

  const { onBoundsUpdate } = props;
  useEffect(() => {
    const el = dropTargetMask.current;
    if (el) {
      const rect = scaledXYCoord(el.getBoundingClientRect(), canvasScaleFactor);
      if (
        rect.x !== dropTargetOffset.current?.x ||
        rect.y !== dropTargetOffset.current?.y
      ) {
        dropTargetOffset.current = {
          x: rect.x,
          y: rect.y,
        };
        onBoundsUpdate && onBoundsUpdate(rect);
      }
    }
  }, [canvasScaleFactor, onBoundsUpdate, props]);

  /*
  When the parent offsets are not updated, we don't need to show the drag preview,
  as the drag preview will be rendered at incorrect coordinates.
  We can be sure that the parent offset has been calculated
  when the coordinates are not [0,0].
  */

  const skipRender =
    (!isDragging || !props.visible || !props.isOver || !currentOffset) &&
    !props.force &&
    !props.isResizing;

  const preview = useMemo(() => {
    if (skipRender) return null;

    const willShow = props.visible && props.isOver && currentOffset;

    const widgetsToPreview =
      selectedWidgets.length > 1 ? selectedWidgets : [widget];

    return (
      willShow &&
      dropTargetOffset.current &&
      widgetsToPreview.map((selectedWidget) => {
        return (
          <WidgetDragPreview
            ref={
              selectedWidget.widgetId === widget.widgetId
                ? widgetsDragPreviewRef
                : undefined
            }
            key={`widget-drag-preview-${selectedWidget?.widgetId || uuidv4()}`}
            sizeOverride={
              typeof availablePosition === "object" &&
              selectedWidgets.length <= 1
                ? availablePosition
                : undefined
            }
            parentOffset={dropTargetOffset.current as XYCoord}
            parentRowHeight={props.parentRowHeight}
            parentColumnWidth={props.parentColumnWidth}
            widget={selectedWidget}
            draggedWidget={widget}
            parentCols={props.parentCols}
            currentOffset={currentOffset as XYCoord}
            canDrop={availablePosition && props.isAllowedType}
            stackDragPositions={stackDragPositions}
          />
        );
      })
    );
  }, [
    skipRender,
    props.visible,
    props.isOver,
    props.parentRowHeight,
    props.parentColumnWidth,
    props.parentCols,
    props.isAllowedType,
    currentOffset,
    selectedWidgets,
    widget,
    availablePosition,
    stackDragPositions,
  ]);

  const gridStyle = useMemo(() => {
    return {
      backgroundSize: `${props.parentColumnWidth}px ${props.parentRowHeight}px`,
      opacity: skipRender ? 0 : 1,
    };
  }, [props.parentColumnWidth, props.parentRowHeight, skipRender]);

  return (
    <WrappedDragLayer
      $padding={props.padding}
      data-test="drag-layer-component"
      ref={dragLayerRef}
    >
      <DotGridLayer
        data-test={`dot-grid-layer-${props.parentWidgetId}`}
        id={`dot-grid-layer-${props.parentWidgetId}`}
        ref={dropTargetMask}
        style={gridStyle}
      />
      {preview}
    </WrappedDragLayer>
  );
};
export default DragLayerComponent;
