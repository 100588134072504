import { Dimension } from "@superblocksteam/shared";
import React, { forwardRef, Ref, useMemo } from "react";
import { XYCoord } from "react-dnd";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { WIDGET_DRAG_PREVIEW_CLASS } from "legacy/constants/WidgetConstants";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { getOverridePos, StackDragPositions } from "../StackLayout/utils";
import MeasuringMark from "./MeasuringMark";
import { snapToGrid, Rect, diffFromDraggedWidget } from "./ResizableUtils";
import type { WidgetPropsRuntime } from "../BaseWidget";

export const getNewWidgetDragOffset = (parentColumnSpace: number) => {
  return {
    x: parentColumnSpace,
    y: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  };
};

const SnappedWidgetsDragPreviewWrapper = styled.div`
  position: absolute;
  will-change: transform, width, height;
  /* 30ms is ~2 frames at 60fps */
  transition: transform 30ms linear, width 30ms linear, height 30ms linear;
  z-index: 1;

  // Default state colors
  // background color is set by sb theme
  border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  box-shadow: inset 0 0 0 3px
    ${({ theme }) =>
      tinycolor(theme.colors.ACCENT_BLUE_NEW_DARKER)
        .setAlpha(0.16)
        .toRgbString()};

  // error state
  &[data-candrop="false"] {
    border: 1px solid ${({ theme }) => theme.colors.DANGER};
    box-shadow: inset 0 0 0 3px
    ${({ theme }) =>
      tinycolor(theme.colors.DANGER_BRIGHT).setAlpha(0.24).toRgbString()};
  
`;

const InnerContainer = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.DRAG_PREVIEW_BLUE};

  &[data-candrop="false"] {
    background: ${({ theme }) =>
      tinycolor(theme.colors.DANGER_BRIGHT).setAlpha(0.08).toRgbString()};
  }
`;

const paddingStyle = { padding: 4 };
const DrawPreviewWithMeasurements = (
  props: {
    lineDirection: "vertical" | "horizontal" | "both" | "none";
    innerStyle: React.CSSProperties;
  } & Parameters<typeof SnappedWidgetsDragPreviewWrapper>[0],
) => {
  const showHorizontal =
    props.lineDirection === "both" || props.lineDirection === "horizontal";
  const showVertical =
    props.lineDirection === "both" || props.lineDirection === "vertical";

  return (
    <SnappedWidgetsDragPreviewWrapper
      {...props}
      className={WIDGET_DRAG_PREVIEW_CLASS}
      style={props.innerStyle}
      data-candrop={props.canDrop}
    >
      <InnerContainer data-candrop={props.canDrop}>
        <svg width="100%" height="100%" style={paddingStyle}>
          <MeasuringMark visible={showHorizontal} lineDirection="horizontal" />
          <MeasuringMark visible={showVertical} lineDirection="vertical" />
        </svg>
      </InnerContainer>
    </SnappedWidgetsDragPreviewWrapper>
  );
};

type WidgetsDragPreviewProps = {
  currentOffset: XYCoord;
  widget: WidgetPropsRuntime;
  draggedWidget: WidgetPropsRuntime;
  parentCols: number;
  parentOffset: XYCoord;
  parentRowHeight: number;
  parentColumnWidth: number;
  canDrop: boolean;
  sizeOverride?: Rect;
  stackDragPositions?: StackDragPositions;
};

const getSnappedXY = (
  parentColumnWidth: number,
  parentRowHeight: number,
  currentOffset: XYCoord,
  parentOffset: XYCoord,
) => {
  // TODO(abhinav): There is a simpler math to use.
  const [leftColumn, topRow] = snapToGrid(
    parentColumnWidth,
    parentRowHeight,
    currentOffset.x - parentOffset.x,
    currentOffset.y - parentOffset.y,
  );

  return {
    X: leftColumn * parentColumnWidth,
    Y: topRow * parentRowHeight,
  };
};

// This component is the blue (or red if invalid & not able to be dropped)
// rectangle representing the component being dragged
const WidgetsDragPreview = forwardRef(
  (
    {
      widget,
      draggedWidget,
      currentOffset,
      parentOffset,
      parentColumnWidth,
      parentRowHeight,
      canDrop,
      sizeOverride,
      stackDragPositions,
    }: WidgetsDragPreviewProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    let widgetColumns = 0;
    let widgetRows = 0;

    // Initial columns/rows are set when dragging from component panel
    // otherwise, use the previous size

    const overridenDraggedWidget = {
      ...draggedWidget,
      ...getOverridePos(draggedWidget, stackDragPositions),
    };
    const overriddenWidget = {
      ...widget,
      ...getOverridePos(widget, stackDragPositions),
    };

    if (overriddenWidget) {
      widgetColumns = Dimension.toGridUnit(
        overriddenWidget.width,
        parentColumnWidth,
      ).roundUp().value;
      widgetRows = Dimension.toGridUnit(
        overriddenWidget.height,
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw().value;
    }

    const hasWidthChanged = sizeOverride
      ? sizeOverride?.right - sizeOverride?.left !== widgetColumns
      : false;
    const hasHeightChanged = sizeOverride
      ? sizeOverride?.bottom - sizeOverride?.top !== widgetRows
      : false;

    if (sizeOverride) {
      widgetColumns = sizeOverride.right - sizeOverride.left;
      widgetRows = sizeOverride.bottom - sizeOverride.top;
    }

    const lineDirection =
      hasWidthChanged && hasHeightChanged
        ? "both"
        : hasWidthChanged
        ? "horizontal"
        : hasHeightChanged
        ? "vertical"
        : "none";

    const diffFromDragged = diffFromDraggedWidget(
      overridenDraggedWidget,
      overriddenWidget,
      parentColumnWidth,
      parentRowHeight,
    );
    const overrideOffset = sizeOverride
      ? {
          X: sizeOverride?.left * parentColumnWidth,
          Y: sizeOverride?.top * parentRowHeight,
        }
      : undefined;

    const adjustedCurrentOffset = {
      x: currentOffset.x - diffFromDragged.x,
      y: currentOffset.y - diffFromDragged.y,
    };

    /**
     * We need to take into account the offset
     * of each component relative to where we started
     * the drag to ensure that this
     * preview component's position is relative to
     * it's original X,Y position and not relative to the
     * x,y of the component being dragged,
     * which could be a different component.
     */
    const snappedXY =
      overrideOffset ??
      getSnappedXY(
        parentColumnWidth,
        parentRowHeight,
        widget.widgetId === draggedWidget.widgetId
          ? currentOffset
          : adjustedCurrentOffset,
        parentOffset,
      );

    const innerStyle = useMemo(
      () => ({
        transform: `translate3d(${snappedXY.X}px,${snappedXY.Y}px,0)`,
        width: widgetColumns * parentColumnWidth,
        height: widgetRows * parentRowHeight,
      }),
      [
        parentColumnWidth,
        parentRowHeight,
        snappedXY.X,
        snappedXY.Y,
        widgetColumns,
        widgetRows,
      ],
    );

    return (
      <React.Fragment>
        <DrawPreviewWithMeasurements
          data-test="widgets-drag-preview-item"
          ref={ref}
          canDrop={canDrop}
          lineDirection={lineDirection}
          innerStyle={innerStyle}
        />
      </React.Fragment>
    );
  },
);

WidgetsDragPreview.displayName = "WidgetsDragPreview";

export default WidgetsDragPreview;
