import type { Typographies } from "@superblocksteam/shared/src/types/application";

export const THEME_STYLE_DEFAULTS = {
  primaryTextColor: "colors.contrastText",
  primaryBackgroundColor: "colors.primary500",
  secondaryTextColor: "colors.primary500",
  secondaryBorderColor: "colors.primary500",
  tertiaryTextColor: "colors.primary500",
};

export const DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT: keyof Typographies =
  "buttonLabel";
