import { Classes } from "@blueprintjs/core";
import { Dimension } from "@superblocksteam/shared";
import React from "react";
import styled from "styled-components";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { useAppSelector } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import { labelStyle } from "../Shared/widgetLabelStyles";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import {
  DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
  DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
} from "./Constants";
import DatePickerComponent, {
  INPUT_ADDITIONAL_MIN_HEIGHT,
  LABEL_EXTRA_HEIGHT_MARGIN,
} from "./DatePickerComponent";
import { type DatePickerComponentWithLayoutsManagedProps } from "./types";

const InputStyleOverrides = styled.div<{
  $height: number;
  hasLabel?: boolean;
}>`
  &&&& .${Classes.INPUT} {
    height: 100%;
  }

  &&& label {
    ${labelStyle.vertical}
  }

  &&& .${CLASS_NAMES.DATEPICKER} {
    margin-top: 0;
  }

  overflow-y: hidden;
`;

export const DatePickerComponentWithLayoutManaged = (
  props: DatePickerComponentWithLayoutsManagedProps,
) => {
  const typographies = useAppSelector(selectGeneratedThemeTypographies);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputProps = useTypographyStyling({
    textStyle: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "label",
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps?.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "input",
  });

  // inputProps.textStyleVariant already has the the default fallback in place.
  // This method still requires it.
  const inputLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: inputProps?.textStyleVariant,
    nestedProps: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
    typographies,
  });

  const { componentHeight } = getComponentDimensions(props);
  const hasLabel = Boolean(props.label?.trim?.().length);
  const isInvalid = !props.isValid && props.isTouched;

  return (
    <InputStyleOverrides $height={componentHeight} hasLabel={hasLabel}>
      <DatePickerComponent
        label={props.label}
        dateFormat={props.dateFormat}
        displayDateFormat={props.displayDateFormat}
        widgetId={props.widgetId}
        isDisabled={props.isDisabled}
        datePickerType={props.datePickerType}
        onDateSelected={props.onDateSelected}
        selectedDate={props.selectedDate ?? props.defaultDate}
        isLoading={props.isLoading}
        minDate={props.minDate}
        maxDate={props.maxDate}
        isValid={!isInvalid}
        isRequired={props.isRequired}
        twentyFourHourTime={props.twentyFourHourTime}
        displayTimezone={
          props.manageTimezone ? props.displayTimezone : undefined
        }
        timezone={props.manageTimezone ? props.timezone : undefined}
        showTimezone={props.manageTimezone}
        showCalendarIcon={props.showIcon}
        onDatePickerClosed={props.onDatePickerClosed}
        labelClassName={labelClass}
        labelStyleOverride={labelProps?.style}
        inputClassName={inputClass}
        inputStyleOverride={inputProps?.style}
        inputLineHeightPx={inputLineHeightPx}
      />
    </InputStyleOverrides>
  );
};

export const estimateInitialDatepickerWidgetHeightGU = (
  typographyEnabled: boolean,
  theme?: GeneratedTheme,
): number => {
  if (!theme?.typographies || !typographyEnabled) {
    return 5;
  }

  const labelHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
  });

  const inputHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
  });

  const totalHeightPx =
    labelHeight +
    LABEL_EXTRA_HEIGHT_MARGIN +
    inputHeight +
    INPUT_ADDITIONAL_MIN_HEIGHT;

  return Dimension.toGridUnit(
    Dimension.px(totalHeightPx),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};
