import { CalloutType } from "./Constants";
import type { GeneratedTheme } from "legacy/themes/types";

export const getColorByCalloutType = (
  generatedTheme: GeneratedTheme,
  type: CalloutType,
) => {
  const themeColors = generatedTheme.colors;
  switch (type) {
    case CalloutType.INFO:
      return themeColors.info;
    case CalloutType.SUCCESS:
      return themeColors.success;
    case CalloutType.WARNING:
      return themeColors.warning;
    case CalloutType.DANGER:
      return themeColors.danger;
    default:
      return themeColors.info;
  }
};
