import {
  PropertyPaneControlConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { ButtonStyle } from "../Shared/Button";
import { THEME_STYLE_DEFAULTS } from "./constants";
import { ButtonWidgetProps } from "./types";

const hidePropertyUnlessButtonTypeIs =
  (buttonStyle: ButtonStyle) => (props: ButtonWidgetProps) =>
    props.buttonStyle !== buttonStyle;

export const buttonColorStyles = (
  propertyNamePrefix: string = "",
): PropertyPaneControlConfig[] => {
  // Primary button colors
  return [
    {
      propertyName: `${propertyNamePrefix}textColor`,
      label: "Text color",
      helpText: "Changes the color of the label",
      controlType: "COLOR_PICKER",
      isJSConvertible: true,
      themeValue: "dynamic", // set based on the background color
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: hidePropertyUnlessButtonTypeIs("PRIMARY_BUTTON"),
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: `${propertyNamePrefix}backgroundColor`,
      key: "backgroundColor",
      label: "Background",
      helpText: "Changes the color of the background",
      controlType: "COLOR_PICKER",
      themeValue: THEME_STYLE_DEFAULTS.primaryBackgroundColor,
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: hidePropertyUnlessButtonTypeIs("PRIMARY_BUTTON"),
      propertyCategory: PropsPanelCategory.Appearance,
    },
    // Secondary button colors
    {
      propertyName: `${propertyNamePrefix}textColor`,
      key: "secondaryTextColor",
      label: "Text color",
      helpText: "Changes the color of the button label",
      controlType: "COLOR_PICKER",
      isJSConvertible: true,
      themeValue: THEME_STYLE_DEFAULTS.secondaryTextColor,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: hidePropertyUnlessButtonTypeIs("SECONDARY_BUTTON"),
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: `${propertyNamePrefix}backgroundColor`, // we always use background color, but it will impact border
      key: "secondaryBackgroundColor",
      label: "Border",
      helpText: "Changes the color of the border",
      controlType: "COLOR_PICKER",
      themeValue: THEME_STYLE_DEFAULTS.secondaryBorderColor,
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: hidePropertyUnlessButtonTypeIs("SECONDARY_BUTTON"),
      propertyCategory: PropsPanelCategory.Appearance,
    },
    // Tertiary button styles
    {
      propertyName: `${propertyNamePrefix}textColor`,
      key: "tertiaryTextColor",
      label: "Text color",
      helpText: "Changes the color of the button",
      controlType: "COLOR_PICKER",
      isJSConvertible: true,
      themeValue: THEME_STYLE_DEFAULTS.tertiaryTextColor,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: hidePropertyUnlessButtonTypeIs("TERTIARY_BUTTON"),
      propertyCategory: PropsPanelCategory.Appearance,
    },
  ];
};
