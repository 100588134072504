import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension } from "@superblocksteam/shared";
import React, { useCallback, useEffect, useRef } from "react";
import "utils/polyfills/requestIdleCallback";
import { DragLayerMonitor, XYCoord, useDragLayer } from "react-dnd";
import { useAnimationThrottle } from "hooks/ui";
import { CanvasDefaults, CanvasLayout } from "legacy/constants/WidgetConstants";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";
import { DROP_LINE_THICKNESS } from "./constants";
import { useDropClientRects } from "./hooks";
import { RECT_GETTERS, computeClosestChildInStack } from "./utils";

type PlaceholderPos = {
  isActive?: boolean;
  left: number;
  top: number;
  width?: number | string;
  height?: number | string;
};

type DragAndDropInfo = {
  isDragging: boolean;
  insertionIndex?: number;
  placeholders?: PlaceholderPos[];
};

export const useStackDragLayer = (params: {
  parentWidgetId: string;
  childWidgetIds: string[];
  layout: CanvasLayout;
}) => {
  const { parentWidgetId, childWidgetIds, layout } = params;

  const parentWidgetPadding = useAppSelector((state) => {
    return (
      getFlattenedCanvasWidget(state, parentWidgetId).padding ??
      selectGeneratedTheme(state).padding
    );
  });

  const parentSpacing = useAppSelector((state) => {
    return getFlattenedCanvasWidget(state, parentWidgetId).spacing;
  });

  const parentSpacingGap = (parentSpacing ?? CanvasDefaults.SPACING).value;

  const { getBasePositionInfo, getChildrenPositionInfo } = useDropClientRects();

  const lastRef: React.MutableRefObject<DragAndDropInfo | null> = useRef(null);

  const throttledInternal = useRef<(monitor: DragLayerMonitor) => void>(
    () => undefined,
  );

  const selectedWidgets = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.selectedWidgets,
  );

  const computeDragInfoFromPos = useCallback(
    (params: { currentOffset: XYCoord | null; draggedWidgetId: string }) => {
      const { currentOffset } = params;

      const baseRect = getBasePositionInfo(parentWidgetId);

      // if dragging over someone else, then skip
      if (
        !currentOffset ||
        !baseRect ||
        currentOffset.x < baseRect.left ||
        currentOffset.x > baseRect.right ||
        currentOffset.y < baseRect.top ||
        currentOffset.y > baseRect.bottom
      ) {
        return null;
      }
      const isVertical = layout === CanvasLayout.VSTACK;
      const { major } = isVertical
        ? RECT_GETTERS.vertical
        : RECT_GETTERS.horizontal;

      const childRects = getChildrenPositionInfo(parentWidgetId);
      // Technically not the geometrically closest child. Just an accurate enough to know where the cursor is within the stack
      const closestChild = computeClosestChildInStack({
        childRects,
        isVertical,
        x: currentOffset.x,
        y: currentOffset.y,
      });

      // we have to filter childWidgetIds because modals and slideouts take up space in the child arr, despite being
      // removed entirely from the layout of a stack
      const filteredChildIds = childWidgetIds.filter(
        (childId) => childRects[childId] != null,
      );

      const hasDraggedOnlyWidgetInParent =
        filteredChildIds.length === 1 &&
        filteredChildIds[0] === params.draggedWidgetId;

      let filteredInsertionIndex = 0;
      let placeholders: PlaceholderPos[] | undefined;
      // determine if the pointer is before or after the midpoint
      if (closestChild.widgetId != null) {
        const childIndex = filteredChildIds.indexOf(closestChild.widgetId);

        const isAfterMidpoint = closestChild.posIsAfterMidpoint ?? false;
        filteredInsertionIndex =
          !hasDraggedOnlyWidgetInParent && isAfterMidpoint
            ? childIndex + 1
            : childIndex;
        // Find the middle of the gap between the two widgets
        const [beforeChildIndex, afterChildIndex] = isAfterMidpoint
          ? [childIndex, childIndex + 1]
          : [childIndex - 1, childIndex];
        const beforeChildId = filteredChildIds[beforeChildIndex];
        const afterChildId = filteredChildIds[afterChildIndex];

        const selectedSet = new Set(selectedWidgets);

        // a rather unintuitive way to determine whether or not the selected widgets are perfectly consecutive
        const maxMin = selectedWidgets.reduce(
          (acc: { maxIndex: number; minIndex: number }, widgetId) => {
            const widgetIndex = filteredChildIds.indexOf(widgetId);
            if (widgetIndex < 0) {
              return acc; // this should not happen
            }
            acc.maxIndex = Math.max(acc.maxIndex, widgetIndex);
            acc.minIndex = Math.min(acc.minIndex, widgetIndex);
            return acc;
          },
          {
            maxIndex: -1,
            minIndex: Number.MAX_SAFE_INTEGER,
          },
        );
        // Our objective is to show the placeholders in full size when hovering the dragged item(s) over their original location
        // This is because dropping here would not cause a change in layout, so showing the full size placeholders is more intuitive.
        // When selecting multiple widgets, hovering over "original location" is less meaningful if the selected widgets are not consecutive.
        // This is because dropping non-consecutive widgets over ANY location is going to result in some change in layout.
        const selectedWidgetsAreConsecutive =
          maxMin.maxIndex - maxMin.minIndex + 1 === selectedWidgets.length;
        const isInsertingNewElement = maxMin.maxIndex === -1;

        const noopDrop =
          selectedWidgetsAreConsecutive &&
          (selectedSet.has(beforeChildId) || selectedSet.has(afterChildId));
        placeholders = [];
        if (selectedWidgets?.length > 0) {
          selectedWidgets.forEach((widgetId) => {
            const draggedRect = childRects?.[widgetId]?.[0];
            if (draggedRect) {
              placeholders?.push({
                isActive: noopDrop,
                left: draggedRect.left - baseRect.left,
                top: draggedRect.top - baseRect.top,
                width: draggedRect.width,
                height: draggedRect.height,
              });
            }
          });
        }

        if (hasDraggedOnlyWidgetInParent) {
          return;
        }

        // loop through all possible placeholder positions aka insertion indexes
        const potentialInsertionIndexes = Array.from(
          { length: filteredChildIds.length + 1 },
          (_, i) => i,
        );
        potentialInsertionIndexes
          .filter((potentialIdx) => {
            // We should hide the placeholder if and only if inserting here would do nothing.
            // - When the insertion index is touching the selected widget(s)
            // - Don't hide the placeholders if dragging from outside the stack, or if there are multiple widgets and they are not consecutive
            if (isInsertingNewElement || !selectedWidgetsAreConsecutive) {
              return true;
            }
            if (
              selectedSet.has(filteredChildIds[potentialIdx]) ||
              selectedSet.has(filteredChildIds[potentialIdx - 1])
            ) {
              return false;
            }
            return true;
          })
          .forEach((potentialIdx) => {
            let gapSize = parentSpacingGap;
            const startPaddingGap =
              major.start(parentWidgetPadding as PaddingAsRect)?.value ?? 0;
            const endPaddingGap =
              major.end(parentWidgetPadding as PaddingAsRect)?.value ?? 0;
            if (potentialIdx === filteredChildIds.length) {
              gapSize = endPaddingGap;
            } else if (potentialIdx === 0) {
              gapSize = startPaddingGap;
            }
            gapSize /= 2;

            let offsetPx: number;
            if (potentialIdx === filteredChildIds.length) {
              const lastWidgetId =
                filteredChildIds[filteredChildIds.length - 1];
              const rect = childRects[lastWidgetId]?.[0];
              if (!rect) {
                return;
              }
              offsetPx = major.end(rect) - DROP_LINE_THICKNESS / 2 + gapSize;
            } else {
              const widgetId = filteredChildIds[potentialIdx];
              const rect = childRects[widgetId]?.[0];
              if (!rect) {
                return;
              }
              offsetPx = major.start(rect) - DROP_LINE_THICKNESS / 2 - gapSize;
            }

            if (isVertical) {
              const clamp = (top: number) => {
                return Math.max(
                  0,
                  Math.min(top, baseRect.height - DROP_LINE_THICKNESS),
                );
              };
              placeholders?.push({
                isActive: potentialIdx === filteredInsertionIndex,
                left: parentWidgetPadding?.left?.value ?? 0,
                top: clamp(offsetPx - baseRect.top),
                width:
                  baseRect.width -
                  (parentWidgetPadding?.right?.value ?? 0) -
                  (parentWidgetPadding?.left?.value ?? 0),
              });
            } else {
              const clamp = (left: number) => {
                return Math.max(
                  0,
                  Math.min(left, baseRect.width - DROP_LINE_THICKNESS),
                );
              };
              placeholders?.push({
                isActive: potentialIdx === filteredInsertionIndex,
                left: clamp(offsetPx - baseRect.left),
                top: parentWidgetPadding?.top?.value ?? 0,
                height:
                  baseRect.height -
                  (parentWidgetPadding?.bottom?.value ?? 0) -
                  (parentWidgetPadding?.top?.value ?? 0),
              });
            }
          });
      }

      let insertionIndex = filteredInsertionIndex;
      if (insertionIndex === filteredChildIds.length) {
        insertionIndex = childWidgetIds.length;
      } else {
        const widgetAtInsertionIndex = filteredChildIds[filteredInsertionIndex];
        const originalIndex = childRects[widgetAtInsertionIndex]?.[1];
        if (originalIndex != null) {
          insertionIndex = originalIndex;
        }
      }

      return {
        insertionIndex,
        placeholders,
      };
    },
    [
      getBasePositionInfo,
      parentWidgetId,
      layout,
      getChildrenPositionInfo,
      childWidgetIds,
      selectedWidgets,
      parentSpacingGap,
      parentWidgetPadding,
    ],
  );

  useEffect(() => {
    throttledInternal.current = (monitor: DragLayerMonitor) => {
      if (!monitor.isDragging()) {
        if (!lastRef.current || lastRef.current.isDragging) {
          lastRef.current = {
            isDragging: false,
          };
        }
        // Important: Skip expensive calculation when not dragging
        return;
      }

      const result = {
        ...computeDragInfoFromPos({
          currentOffset: monitor.getClientOffset(),
          draggedWidgetId: monitor.getItem()?.widgetId,
        }),
        isDragging: true,
      };

      // This maintains referential equality unless there's been a change.
      // The most commonly updated property is availablePosition
      if (!equal(lastRef.current, result)) {
        lastRef.current = result;
      }
    };
  }, [computeDragInfoFromPos]);

  const throttledUpdateWrapper = useCallback((monitor: DragLayerMonitor) => {
    throttledInternal.current(monitor);
  }, []);
  const throttledUpdate = useAnimationThrottle(throttledUpdateWrapper);
  const collected: DragAndDropInfo | null = useDragLayer((monitor) => {
    if (!monitor.isDragging()) {
      return {
        isDragging: false,
      };
    }
    throttledUpdate?.(monitor);
    return lastRef.current;
  });

  return [collected, computeDragInfoFromPos] as [
    DragAndDropInfo | null,
    typeof computeDragInfoFromPos,
  ];
};

type PaddingAsRect = {
  left: Dimension<"px"> | undefined;
  right: Dimension<"px"> | undefined;
  top: Dimension<"px"> | undefined;
  bottom: Dimension<"px"> | undefined;
};
