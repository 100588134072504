import { Button } from "antd";
import React from "react";
import { ReactComponent as CustomizationIcon } from "assets/icons/common/customization.svg";
import { ReactComponent as AdvancedIcon } from "legacy/assets/icons/control/advanced-settings.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const unselectedButtonStyle = styleAsClass/*css*/ `
  && {
    height: 32px;
    width: 32px;
    padding: 5px 7px;
    border-color: ${colors.GREY_100};
    svg {
        color: ${colors.GREY_300};
    }
    
    &:hover, &:focus {
      border-color: ${colors.GREY_300};
      svg {
        color: ${colors.GREY_300};
      }
    }
  }
`;

const selectedButtonStyle = styleAsClass/*css*/ `
  && {
    height: 32px;
    width: 32px;
    padding: 5px 7px;

    border-color: ${colors.ACCENT_BLUE_500_25};
    background: ${colors.ACCENT_BLUE_500_25};
    svg {
      color: ${colors.ACCENT_BLUE_500};
    }

    &:hover, &:focus {
        border-color: ${colors.ACCENT_BLUE_500_50};
        background: ${colors.ACCENT_BLUE_500_50};
        svg {
          color: ${colors.ACCENT_BLUE_500};
        }
    }
  }
`;

export const AdvancedModeToggle = ({
  onToggle,
  isAdvancedMode,
  isDisabled,
  type,
}: {
  isAdvancedMode: boolean;
  onToggle: () => void;
  isDisabled?: boolean;
  type: "padding" | "margin" | "border" | "spacing";
}) => {
  return (
    <Button
      data-type={type}
      icon={type === "spacing" ? <CustomizationIcon /> : <AdvancedIcon />}
      onClick={onToggle}
      className={isAdvancedMode ? selectedButtonStyle : unselectedButtonStyle}
      disabled={isDisabled}
    />
  );
};
