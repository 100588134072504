import equal from "@superblocksteam/fast-deep-equal";
import {
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  NonCustomTypography,
  PerCornerBorderRadius,
  PerSideBorder,
  TextStyleBlock,
  Typographies,
} from "@superblocksteam/shared";
import { get } from "lodash";
import { createSelector } from "reselect";
import {
  createThemeOption,
  createThemeTooltip,
} from "legacy/components/propertyControls/ManageThemeHelpers";
import {
  PropsPanelCategory,
  type Hidden,
  type PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  FONT_SIZE_UNIT_OPTIONS,
  FONT_STYLE_OPTIONS,
  LETTER_SPACING_UNIT_OPTIONS,
  LINE_HEIGHT_UNIT_OPTIONS,
  TEXT_TRANSFORM_OPTIONS,
  getFontWeightOptions,
  getNewValueOnUnitChange,
} from "legacy/pages/Editor/Explorer/Theme/TypographyControls";
import {
  selectAvailableFonts,
  selectGeneratedTheme,
  selectGeneratedThemeFontFamily,
  selectGeneratedThemeTypographies,
  selectStoredThemeTypographies,
} from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { EMPTY_RADIUS, NO_BORDER_OBJECT } from "legacy/themes/constants";
import { DEFAULT_FONT_WEIGHTS } from "legacy/themes/typefaceConstants";
import {
  getAvailableFontFamilies,
  getFontFamilyOptions,
} from "legacy/themes/typefaces/utils";
import {
  USER_SELECTABLE_VARIANTS,
  SB_CUSTOM_TEXT_STYLE,
} from "legacy/themes/typographyConstants";
import {
  camelCaseToSentenceCase,
  getCustomTypographyAccessor,
  getLineHeightInPixels,
  isColorToken,
} from "legacy/themes/utils";
import { extractPixels } from "legacy/themes/utils";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { Flag, AllFlags } from "store/slices/featureFlags";
import { type AppState } from "store/types";
import { removeLastPathSegments } from "utils/dottedPaths";
import { type NestedTextStylePropertyNames } from "./BaseWidget";
import type { DropDownControlOption } from "legacy/components/propertyControls/DropDownControl";

const CUSTOM_OPTION: DropDownControlOption = {
  label: "Custom",
  value: SB_CUSTOM_TEXT_STYLE,
};

export const LINE_HEIGHT_WARNING =
  "Line height is smaller than font size. This may cause text to clip";

const FONT_WEIGHT_OPTIONS = getFontWeightOptions();

export const backgroundColorProperty = ({
  hidden,
  defaultColor,
  getAdditionalHiddenData,
}: {
  hidden?: Hidden;
  defaultColor?: string;
  getAdditionalHiddenData?: PropertyPaneControlConfig<any>["getAdditionalHiddenData"];
}) =>
  ({
    propertyName: "backgroundColor",
    label: "Background color",
    helpText: "Changes the color of the background",
    controlType: "COLOR_PICKER",
    propertyCategory: PropsPanelCategory.Appearance,
    themeValue: ({ theme, props }) => {
      const color =
        defaultColor ??
        (props.containerStyle === "none" ? "transparent" : "colors.neutral");

      return {
        value: color,
        treatAsNull: color === "transparent" || color == null,
      };
    },
    isJSConvertible: true,
    isBindProperty: true,
    isTriggerProperty: false,
    hidden,
    getAdditionalHiddenData,
    isRemovable: true,
    visibility: "SHOW_NAME",
  } satisfies PropertyPaneControlConfig);

export const borderProperty = ({
  hidden,
  themeValue,
  defaultValue,
  getAdditionalHiddenData,
}: {
  hidden?: Hidden;
  themeValue?: PerSideBorder;
  defaultValue?: PerSideBorder;
  getAdditionalHiddenData?: PropertyPaneControlConfig<any>["getAdditionalHiddenData"];
}) =>
  ({
    propertyName: "border",
    label: "Border",
    helpText: "Controls the border of the component",
    controlType: "BORDER_CONTROL",
    propertyCategory: PropsPanelCategory.Appearance,
    isJSConvertible: false,
    isBindProperty: true,
    isTriggerProperty: false,
    hidden,
    defaultValue: defaultValue ?? themeValue,
    themeValue: ({ theme, props }) => {
      const isNone = props.containerStyle === "none";
      const value = isNone ? NO_BORDER_OBJECT : themeValue;
      const treatAsNull = value == null || equal(value, NO_BORDER_OBJECT);
      return {
        treatAsNull,
        value: value,
      };
    },
    getAdditionalHiddenData,
    isRemovable: true,
    visibility: "SHOW_NAME",
  } satisfies PropertyPaneControlConfig);

const borderRadiusProperty = ({
  hidden,
  defaultValue,
  getAdditionalHiddenData,
}: {
  hidden?: Hidden;
  defaultValue?: PerCornerBorderRadius;
  getAdditionalHiddenData?: PropertyPaneControlConfig<any>["getAdditionalHiddenData"];
}) =>
  ({
    propertyName: "borderRadius",
    label: "Border radius",
    helpText: "Controls the border radius of the component",
    controlType: "BORDER_RADIUS_CONTROL",
    propertyCategory: PropsPanelCategory.Appearance,
    isJSConvertible: false,
    isBindProperty: true,
    isTriggerProperty: false,
    hidden,
    defaultValue: EMPTY_RADIUS,
    themeValue: ({ theme, props }) => {
      const themeDefault =
        defaultValue ?? createPerCornerBorderRadius(theme.borderRadius);
      const isNone = props.containerStyle === "none";
      const value = isNone ? undefined : themeDefault;
      const treatAsNull = value == null || equal(value, EMPTY_RADIUS);
      return {
        treatAsNull,
        value: value,
      };
    },
    getAdditionalHiddenData,
    isRemovable: true,
    visibility: "SHOW_NAME",
  } satisfies PropertyPaneControlConfig);

const hiddenCustomStyles =
  (
    propertyNamespaceDottedPath: string,
  ): NonNullable<PropertyPaneControlConfig["hidden"]> =>
  (props: any) => {
    return (
      get(props, `${propertyNamespaceDottedPath}.textStyle.variant`) !==
      SB_CUSTOM_TEXT_STYLE
    );
  };

export const customStylesProperties = <NestedPath extends string>({
  textStyleParentDottedPath,
  additionalHidden,
  overrideHidden,
  getDynamicTextStyleParentDottedPath,
}: {
  textStyleParentDottedPath: NestedPath;
  additionalHidden?: Hidden; // if return true, then hide. If false, then we check to hide when custom styles is actually active
  overrideHidden?: Hidden; // use this value regardless of if we determine custom styles are actually active
  getDynamicTextStyleParentDottedPath?: (path: string) => string;
}) => {
  const pathToProperty = (
    propertyName: keyof Omit<TextStyleBlock, "textColor">,
  ) => {
    return `${textStyleParentDottedPath}.textStyle.${propertyName}` as keyof NestedTextStylePropertyNames<NestedPath>;
  };

  const properties: PropertyPaneControlConfig<
    NestedTextStylePropertyNames<NestedPath>
  >[] = [
    {
      propertyName: pathToProperty("fontFamily"),
      label: "Typeface",
      controlType: "DROP_DOWN",
      propertyCategory: PropsPanelCategory.Appearance,
      options: [],
      getAdditionalDataForPropFunc: {
        theme: selectGeneratedTheme,
      },
      optionsFuncWithAdditionalData: ({
        props,
        additionalDataForPropFunc,
      }: {
        props: any;
        additionalDataForPropFunc?: Record<string, any>;
      }) => {
        const options = getFontFamilyOptions(
          additionalDataForPropFunc?.theme?.availableFonts,
        );

        options.unshift({
          label: `Theme default (${additionalDataForPropFunc?.theme?.fontFamily})`,
          value: "inherit",
        });
        return options;
      },
      isBindProperty: false,
      isTriggerProperty: false,
      defaultValue: "inherit",
      updateHook: (props, propertyPath, propertyValue, additionalData) => {
        if (!additionalData) return [];
        let fontFamily = propertyValue;
        if (fontFamily === "inherit") {
          fontFamily = additionalData?.theme?.fontFamily;
        }
        const availableFonts = getAvailableFontFamilies(
          additionalData.theme.availableFonts,
        );
        // check if the font family has the font weight
        const fontWeight = get(props, pathToProperty("fontWeight"));
        const availableWeights =
          availableFonts[fontFamily]?.weights ?? DEFAULT_FONT_WEIGHTS;
        if (!availableWeights.includes(Number(fontWeight))) {
          return [
            {
              propertyPath: pathToProperty("fontWeight"),
              propertyValue: availableWeights[0],
            },
          ];
        }
      },
    },
    {
      propertyName: pathToProperty("fontSize"),
      label: "Font size",
      defaultUnit: "px",
      unitOptions: FONT_SIZE_UNIT_OPTIONS,
      precision: 0,
      controlType: "INPUT_NUMBER",
      propertyCategory: PropsPanelCategory.Appearance,
      isBindProperty: false,
      isTriggerProperty: false,
      minValue: 1,
    },
    {
      propertyName: pathToProperty("fontWeight"),
      label: "Font weight",
      controlType: "DROP_DOWN",
      propertyCategory: PropsPanelCategory.Appearance,
      getAdditionalDataForPropFunc: {
        themeTypographies: selectGeneratedThemeTypographies,
        themeFontFamily: selectGeneratedThemeFontFamily,
        availableFonts: selectAvailableFonts,
      },
      optionsFuncWithAdditionalData: ({
        props,
        additionalDataForPropFunc,
        propertyName,
      }: {
        props: any;
        additionalDataForPropFunc?: Record<string, any>;
        propertyName: string;
      }) => {
        let fontFamily = get(props, pathToProperty("fontFamily"));

        if (getDynamicTextStyleParentDottedPath) {
          fontFamily = get(
            props,
            getDynamicTextStyleParentDottedPath?.(propertyName) +
              ".textStyle.fontFamily",
          );
        }

        if (fontFamily === "inherit") {
          fontFamily = additionalDataForPropFunc?.themeFontFamily;
        }
        const availableFonts = getAvailableFontFamilies(
          additionalDataForPropFunc?.availableFonts,
        );
        const availableWeights =
          availableFonts[fontFamily]?.weights ?? DEFAULT_FONT_WEIGHTS;

        const filteredFontWeightOptions = FONT_WEIGHT_OPTIONS.filter((option) =>
          availableWeights.includes(Number(option.value)),
        );
        return filteredFontWeightOptions;
      },
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("fontStyle"),
      label: "Font style",
      controlType: "DROP_DOWN",
      propertyCategory: PropsPanelCategory.Appearance,
      options: FONT_STYLE_OPTIONS,
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("lineHeight"),
      label: "Line height",
      controlType: "INPUT_NUMBER",
      propertyCategory: PropsPanelCategory.Appearance,
      defaultUnit: "px",
      unitOptions: LINE_HEIGHT_UNIT_OPTIONS,
      transformValueOnUnitChange: ({
        oldUnit,
        newUnit,
        value,
        widgetProperties,
        path,
      }: {
        oldUnit: string | undefined;
        newUnit: string;
        value: unknown;
        widgetProperties?: any;
        path: string;
      }) => {
        let fontSize = get(widgetProperties, pathToProperty("fontSize"));
        if (getDynamicTextStyleParentDottedPath) {
          fontSize = get(
            widgetProperties,
            getDynamicTextStyleParentDottedPath?.(path) + ".textStyle.fontSize",
          );
        }
        if (fontSize == null) {
          return value as number;
        }

        const currentFontSize: number = extractPixels(fontSize);

        return getNewValueOnUnitChange({
          oldUnit,
          newUnit,
          value,
          currentFontSize,
          defaultRatio: 1.2,
        });
      },
      warningFunc: ({ props, propertyName }) => {
        let fontSize = get(props, pathToProperty("fontSize"));
        if (getDynamicTextStyleParentDottedPath) {
          fontSize = get(
            props,
            getDynamicTextStyleParentDottedPath?.(propertyName) +
              ".textStyle.fontSize",
          );
        }

        if (typeof fontSize !== "string") {
          return;
        }

        const currentFontSize: number = extractPixels(fontSize);

        let lineHeight = get(props, pathToProperty("lineHeight"));
        if (getDynamicTextStyleParentDottedPath) {
          lineHeight = get(
            props,
            getDynamicTextStyleParentDottedPath?.(propertyName) +
              ".textStyle.lineHeight",
          );
        }

        if (lineHeight == null) {
          return;
        }
        const lineHeightInpx = getLineHeightInPixels(
          lineHeight,
          currentFontSize,
        );

        if (lineHeightInpx < currentFontSize) {
          return LINE_HEIGHT_WARNING;
        }
      },
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("letterSpacing"),
      label: "Letter spacing",
      defaultUnit: "em",
      unitOptions: LETTER_SPACING_UNIT_OPTIONS,
      transformValueOnUnitChange: ({
        oldUnit,
        newUnit,
        value,
        widgetProperties,
        path,
      }: {
        oldUnit: string | undefined;
        newUnit: string;
        value: unknown;
        widgetProperties?: any;
        path: string;
      }) => {
        let fontSize = get(widgetProperties, pathToProperty("fontSize"));

        if (getDynamicTextStyleParentDottedPath) {
          fontSize = get(
            widgetProperties,
            getDynamicTextStyleParentDottedPath?.(path) +
              ".textStyle.fontFamily",
          );
        }
        if (fontSize == null) {
          return value as number;
        }

        const currentFontSize: number = extractPixels(fontSize);

        return getNewValueOnUnitChange({
          oldUnit,
          newUnit,
          value,
          currentFontSize,
          defaultRatio: 0,
        });
      },
      controlType: "INPUT_NUMBER",
      propertyCategory: PropsPanelCategory.Appearance,
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("textTransform"),
      label: "Text transform",
      controlType: "DROP_DOWN",
      propertyCategory: PropsPanelCategory.Appearance,
      options: TEXT_TRANSFORM_OPTIONS,
      isBindProperty: false,
      isTriggerProperty: false,
    },
  ];

  const defaultHidden = hiddenCustomStyles(textStyleParentDottedPath);
  const compoundHidden: PropertyPaneControlConfig["hidden"] = (
    props,
    propertyPath,
    featureFlags,
    additionalHiddenData,
    theme,
  ) => {
    if (featureFlags?.[Flag.ENABLE_TYPOGRAPHY] === false) {
      return true;
    }

    if (overrideHidden) {
      return overrideHidden(
        props,
        propertyPath,
        featureFlags,
        additionalHiddenData,
        theme,
      );
    }

    if (
      additionalHidden &&
      additionalHidden(
        props,
        propertyPath,
        featureFlags,
        additionalHiddenData,
        theme,
      )
    ) {
      return true;
    }

    return defaultHidden(
      props,
      propertyPath,
      featureFlags,
      additionalHiddenData,
      theme,
    );
  };

  const propsWithHidden = properties.map((property) => ({
    ...property,
    hidden: compoundHidden,
  }));

  return propsWithHidden satisfies PropertyPaneControlConfig<
    NestedTextStylePropertyNames<NestedPath>
  >[];
};

// set default value for custom styles based on theme if missing from variant or has value not good for display directly
const setDefaultValueForCustomStyles = ({
  propertyNameShort,
  valueFromVariant,
  additionalDataForPropFunc,
}: {
  propertyNameShort: string | undefined;
  valueFromVariant: string;
  additionalDataForPropFunc: Record<string, any> | undefined;
}) => {
  const value: string = valueFromVariant;
  switch (propertyNameShort) {
    case "fontFamily":
      if (value === "inherit" || !value) {
        return additionalDataForPropFunc?.themeFontFamily;
      }
      return value;
    case "fontStyle":
      return value ?? "normal";
    case "textTransform":
      return value ?? "none";
  }
  return value;
};

export const styleProperties = ({
  defaultBorderProperty,
  defaultBorderRadiusProperty,
}: {
  defaultBorderProperty?: PerSideBorder;
  defaultBorderRadiusProperty?: PerCornerBorderRadius;
}) =>
  [
    backgroundColorProperty({}),
    borderProperty({
      themeValue: defaultBorderProperty,
    }),
    borderRadiusProperty({
      defaultValue: defaultBorderRadiusProperty,
    }),
  ] satisfies PropertyPaneControlConfig[];

const shouldHideTypographyControl: Hidden = (props, path, flags) => {
  return Boolean(flags[Flag.ENABLE_TYPOGRAPHY]) === false;
};

type OptionsCustomizerFn = ({
  propertyName,
  props,
  flags,
}: {
  props: any;
  flags: Partial<AllFlags> | undefined;
  propertyName: string;
  options: DropDownControlOption[];
}) => DropDownControlOption[];

export const textStyleControls = (props: {
  key: string; // e.g. labelProps
  propertyName?: string; // e.g. label. used for hiding. if not provided, then we assume its always visible
  shouldHide?: Hidden; // custom function to determine if it should hide - more control than propertyName
  textStyleLabel: string;
  colorLabelText: string;
  defaultVariant: keyof Typographies;
}) => {
  const {
    key,
    textStyleLabel,
    colorLabelText,
    defaultVariant,
    propertyName,
    shouldHide,
  } = props;

  const hidden: PropertyPaneControlConfig["hidden"] =
    shouldHide ??
    (propertyName
      ? (props, path, flags) => {
          return (
            Boolean(flags[Flag.ENABLE_TYPOGRAPHY]) === false ||
            props[propertyName] == null
          );
        }
      : undefined);

  return [
    textStyleProperty({
      label: textStyleLabel,
      textStyleParentDottedPath: key,
      defaultValueFn: () => defaultVariant,
      hidden,
    }),
    textColorProperty({
      label: colorLabelText,
      textStyleParentDottedPath: key,
      defaultThemeVariant: defaultVariant,
      hidden,
    }),
    ...customStylesProperties<typeof key>({
      textStyleParentDottedPath: key,
      additionalHidden: hidden,
    }),
  ] satisfies Array<PropertyPaneControlConfig>;
};

// Set a default value based on theme if value is undefined or not in the dropdown options
const getValueFromVariant = ({
  propertyNameShort,
  defaultVariant,
  currentVariant,
  themeTypographies,
  props,
}: {
  propertyNameShort: string | undefined;
  defaultVariant?: string;
  currentVariant: string;
  themeTypographies: unknown;
  props: unknown;
}) => {
  if (!propertyNameShort) {
    return undefined;
  }
  const fallbackValue = get(
    themeTypographies,
    `${defaultVariant}.${propertyNameShort}`,
  );
  const value = get(
    themeTypographies,
    `${currentVariant}.${propertyNameShort}`,
  );
  return value ?? fallbackValue;
};

export const textStyleProperty = <NestedPath extends string>({
  textStyleParentDottedPath,
  themeValue,
  defaultValueFn,
  label = "Text style",
  helpText = createThemeTooltip(),
  isBindProperty = false,
  isTriggerProperty = false,
  updateHook,
  isJSConvertible = false,
  customJSControl,
  additionalUserSelectableVariants = [],
  hidden,
  visibility,
  isRemovable,
  resetToThemeBtnText,
  optionsCustomizer,
  getDynamicTextStyleParentDottedPath,
}: {
  textStyleParentDottedPath: NestedPath;
  themeValue?: PropertyPaneControlConfig["themeValue"];
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  label?: PropertyPaneControlConfig["label"];
  helpText?: PropertyPaneControlConfig["helpText"];
  isBindProperty?: PropertyPaneControlConfig["isBindProperty"];
  isTriggerProperty?: PropertyPaneControlConfig["isTriggerProperty"];
  updateHook?: (
    props: any,
    propertyPath: string,
    propertyValue: any,
    additionalDataForPropFunc?: Record<string, any>,
  ) => Array<{ propertyPath: string; propertyValue: any }> | undefined;
  isJSConvertible?: PropertyPaneControlConfig["isJSConvertible"];
  customJSControl?: PropertyPaneControlConfig["customJSControl"];
  hidden?: PropertyPaneControlConfig["hidden"];
  visibility?: PropertyPaneControlConfig["visibility"];
  isRemovable?: PropertyPaneControlConfig["isRemovable"];
  resetToThemeBtnText?: PropertyPaneControlConfig["resetToThemeBtnText"];
  optionsCustomizer?: OptionsCustomizerFn;
  // this function is needed to get the dynamic path from the property name passed into the function
  // for when we define the properties dynamically like in TableWidget columns
  getDynamicTextStyleParentDottedPath?: (path: string) => NestedPath;
  additionalUserSelectableVariants?: Array<NonCustomTypography>;
}) => {
  const config: PropertyPaneControlConfig = {
    propertyName: `${textStyleParentDottedPath}.textStyle.variant`,
    helpText,
    label,
    controlType: "DROP_DOWN",
    propertyCategory: PropsPanelCategory.Appearance,
    themeValue,
    defaultValueFn,
    customJSControl,
    isJSConvertible,
    visibility,
    isRemovable,
    resetToThemeBtnText,
    menuFooterOptions: [createThemeOption()],
    optionsSelector: (state, props, flags, propertyName) => {
      return createSelector(
        selectGeneratedTheme,
        (_state: AppState, theme: GeneratedTheme) => theme,
        (theme) => {
          const options = USER_SELECTABLE_VARIANTS.concat(
            additionalUserSelectableVariants,
          ).map((typefaceName) => {
            return {
              label: camelCaseToSentenceCase(typefaceName),
              value: typefaceName,
              subText:
                theme.typographies[
                  typefaceName as keyof GeneratedTheme["typographies"]
                ]?.fontSize || undefined,
              subTextPosition: "right",
            };
          }) as DropDownControlOption[];

          theme.typographies[CUSTOM_THEME_TYPOGRAPHY_KEY] &&
            Object.entries(
              theme.typographies[CUSTOM_THEME_TYPOGRAPHY_KEY],
            ).forEach(([customKey, entry]) => {
              if (entry) {
                options.push({
                  label: entry.name,
                  value: getCustomTypographyAccessor(customKey),
                  subText: entry?.styles?.fontSize || undefined,
                  subTextPosition: "right",
                });
              }
            });

          const optionsWithCustom = [...options, CUSTOM_OPTION];

          return optionsCustomizer && propertyName
            ? optionsCustomizer({
                propertyName,
                props,
                flags,
                options: optionsWithCustom,
              })
            : optionsWithCustom;
        },
      )(state, props);
    },
    isBindProperty,
    isTriggerProperty,
    hidden: (props, path, flags, additionalHiddenData, theme) => {
      const hideTypographyControl = shouldHideTypographyControl(
        props,
        path,
        flags,
        additionalHiddenData,
        theme,
      );
      if (hidden) {
        return (
          hidden(props, path, flags, additionalHiddenData, theme) ||
          hideTypographyControl
        );
      }
      return hideTypographyControl;
    },
    getAdditionalDataForPropFunc: {
      themeTypographies: selectGeneratedThemeTypographies,
      themeFontFamily: selectGeneratedThemeFontFamily,
      storedTypographies: selectStoredThemeTypographies,
    },
    updateHook: (
      props: any,
      propertyPath: string,
      propertyValue: any,
      additionalDataForPropFunc?: Record<string, any>,
    ) => {
      const defaultVariant = defaultValueFn
        ? defaultValueFn({ props, propertyName: propertyPath })
        : undefined;
      const currentVariant = get(props, propertyPath, defaultVariant);
      const dynamicTextStyleParentDottedPath =
        getDynamicTextStyleParentDottedPath?.(propertyPath) ||
        textStyleParentDottedPath;

      let allCustomStyleUpdates: Array<{
        propertyPath: string;
        propertyValue: any;
      }> = [];

      if (
        currentVariant === SB_CUSTOM_TEXT_STYLE &&
        propertyValue !== SB_CUSTOM_TEXT_STYLE
      ) {
        // set custom style props to undefined
        const customStylesUpdates = customStylesProperties<NestedPath>({
          textStyleParentDottedPath: dynamicTextStyleParentDottedPath,
        }).map((property) => ({
          propertyPath: property.propertyName as string,
          propertyValue: undefined,
        }));

        // set default text color to undefined to inherit variant typography
        customStylesUpdates.push({
          propertyPath: `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
          propertyValue: undefined,
        });

        allCustomStyleUpdates =
          allCustomStyleUpdates.concat(customStylesUpdates);
      }

      if (
        currentVariant !== SB_CUSTOM_TEXT_STYLE &&
        propertyValue === SB_CUSTOM_TEXT_STYLE
      ) {
        const themeTypographies = additionalDataForPropFunc?.themeTypographies;
        // set custom style props to last variant values
        const customStylesUpdates = customStylesProperties<NestedPath>({
          textStyleParentDottedPath: dynamicTextStyleParentDottedPath,
        }).map((property) => {
          const propertyNameShort = (
            property.propertyName as string | undefined
          )
            ?.split(".")
            .pop();
          const valueFromVariant = getValueFromVariant({
            propertyNameShort,
            themeTypographies,
            currentVariant,
            defaultVariant,
            props,
          });

          const value = setDefaultValueForCustomStyles({
            propertyNameShort: propertyNameShort,
            valueFromVariant,
            additionalDataForPropFunc,
          });

          return {
            propertyPath: property.propertyName as string,
            propertyValue: value,
          };
        });

        // update custom text color to last variant value if not user overridden
        if (
          get(
            props,
            `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
          ) === undefined
        ) {
          // generated theme colors will be set to hex values, but stored values will contain theme tokens (i.e. colors.neutral700)
          const storedColor = get(
            additionalDataForPropFunc?.storedTypographies,
            `${currentVariant}.textColor.default`,
          );
          const generatedColor = get(
            themeTypographies,
            `${currentVariant}.textColor.default`,
          );

          if (isColorToken(storedColor)) {
            // set the value to the stored color
            customStylesUpdates.push({
              propertyPath: `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
              propertyValue: `{{ theme.${storedColor} }}`,
            });
          } else {
            customStylesUpdates.push({
              propertyPath: `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
              propertyValue: generatedColor,
            });
          }
        }

        allCustomStyleUpdates =
          allCustomStyleUpdates.concat(customStylesUpdates);
      }

      // if there is already an update hook, we need to run it
      // including on every update entry generated above
      let allUpdates: Array<{
        propertyPath: string;
        propertyValue: any;
      }> = [];

      allUpdates = allUpdates.concat(allCustomStyleUpdates);

      if (updateHook) {
        allUpdates = allUpdates.concat(
          updateHook(props, propertyPath, propertyValue) || [],
        );

        allCustomStyleUpdates.forEach((update) => {
          allUpdates = allUpdates.concat(
            updateHook(props, update.propertyPath, update.propertyValue) || [],
          );
        });
      }

      return allUpdates;
    },
  };

  return config;
};

const defaultColorThemeValueFn = ({
  theme,
  props,
  propertyName,
  defaultThemeVariant,
}: {
  theme: GeneratedTheme;
  props: any;
  propertyName: string;
  defaultThemeVariant: keyof Typographies;
}) => {
  const pathToTextStyleVariant = `${removeLastPathSegments(
    propertyName,
    2,
  )}.variant`;
  let variantName = get(props, pathToTextStyleVariant);
  const variantFromTheme = get(theme.typographies, variantName);

  if (variantName === SB_CUSTOM_TEXT_STYLE || !variantFromTheme) {
    variantName = defaultThemeVariant;
  }

  return {
    value: `typographies.${variantName}.textColor.default`,
    treatAsNull: false,
  };
};

export const textColorProperty = ({
  textStyleParentDottedPath,
  themeValue,
  defaultValueFn,
  label = "Text color",
  hidden,
  isJSConvertible,
  customJSControl,
  isBindProperty = true,
  isTriggerProperty = false,
  defaultThemeVariant,
  updateHook,
}: {
  textStyleParentDottedPath: string;
  themeValue?: PropertyPaneControlConfig["themeValue"];
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  label?: string;
  hidden?: PropertyPaneControlConfig["hidden"];
  isJSConvertible?: PropertyPaneControlConfig["isJSConvertible"];
  customJSControl?: PropertyPaneControlConfig["customJSControl"];
  isBindProperty?: PropertyPaneControlConfig["isBindProperty"];
  isTriggerProperty?: PropertyPaneControlConfig["isTriggerProperty"];
  defaultThemeVariant?: keyof Typographies;
  updateHook?: PropertyPaneControlConfig["updateHook"];
}): PropertyPaneControlConfig => {
  let fullTextStylePath = "textStyle";
  if (textStyleParentDottedPath) {
    fullTextStylePath = `${textStyleParentDottedPath}.textStyle`;
  }

  let themeValueFnToUse = themeValue;
  if (!themeValue && defaultThemeVariant) {
    themeValueFnToUse = ({
      theme,
      props,
      propertyName,
    }: {
      theme: GeneratedTheme;
      props: any;
      propertyName: string;
    }) => {
      return defaultColorThemeValueFn({
        theme,
        props,
        propertyName,
        defaultThemeVariant,
      });
    };
  }

  const config: PropertyPaneControlConfig = {
    propertyName: `${fullTextStylePath}.textColor.default`,
    helpText: "Sets the color of the text",
    label,
    controlType: "COLOR_PICKER",
    propertyCategory: PropsPanelCategory.Appearance,
    themeValue: themeValueFnToUse,
    isJSConvertible,
    customJSControl,
    defaultValueFn,
    isBindProperty,
    isTriggerProperty,
    updateHook,
    hidden: (props, path, flags, additionalHiddenData, theme) => {
      const hideTypographyControl = shouldHideTypographyControl(
        props,
        path,
        flags,
        additionalHiddenData,
        theme,
      );
      if (hidden) {
        return (
          hidden(props, path, flags, additionalHiddenData, theme) ||
          hideTypographyControl
        );
      }
      return hideTypographyControl;
    },
  };

  return config;
};

export const typographyProperties = (params: {
  propertyNameForHumans: string;
  textStyleParentDottedPath: string;
  defaultVariant: keyof Typographies;
  hiddenIfPropertyNameIsNullOrFalse?: string;
}): Array<PropertyPaneControlConfig> => {
  return [
    textStyleProperty({
      label: `${params.propertyNameForHumans} text`,
      textStyleParentDottedPath: params.textStyleParentDottedPath,
      defaultValueFn: () => params.defaultVariant,
      hidden: (props) =>
        !!params.hiddenIfPropertyNameIsNullOrFalse &&
        (props[params.hiddenIfPropertyNameIsNullOrFalse] == null ||
          props[params.hiddenIfPropertyNameIsNullOrFalse] === false),
    }),
    textColorProperty({
      label: `${params.propertyNameForHumans} text color`,
      textStyleParentDottedPath: params.textStyleParentDottedPath,
      defaultThemeVariant: params.defaultVariant,
      hidden: (props) =>
        !!params.hiddenIfPropertyNameIsNullOrFalse &&
        (props[params.hiddenIfPropertyNameIsNullOrFalse] == null ||
          props[params.hiddenIfPropertyNameIsNullOrFalse] === false),
    }),
    ...customStylesProperties({
      textStyleParentDottedPath: params.textStyleParentDottedPath,
      additionalHidden: (props) =>
        !!params.hiddenIfPropertyNameIsNullOrFalse &&
        (props[params.hiddenIfPropertyNameIsNullOrFalse] == null ||
          props[params.hiddenIfPropertyNameIsNullOrFalse] === false),
    }),
  ];
};
