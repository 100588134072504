import { Select, SelectProps } from "antd";
import React from "react";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { colors } from "styles/colors";

const DropdownSelect = (props: SelectProps<string>) => {
  return (
    <Select
      suffixIcon={<ChevronDown color={colors.GREY_300} />}
      {...props}
      removeIcon={null}
    />
  );
};

export default DropdownSelect;
