import Pagination from "rc-pagination";
import React from "react";
import styled from "styled-components";
import { CLASS_NAMES } from "legacy/themes/classnames";

const locale = {
  // Options.jsx
  items_per_page: "/ page",
  jump_to: "Go to",
  jump_to_confirm: "confirm",
  page: "",
  // Pagination.jsx
  prev_page: "Previous Page",
  next_page: "Next Page",
  prev_5: "Previous 5 Pages",
  next_5: "Next 5 Pages",
  prev_3: "Previous 3 Pages",
  next_3: "Next 3 Pages",
};

const StyledPagination = styled(Pagination)<{
  disabled?: boolean;
}>`
  margin: 0 auto;
  padding: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  height: 48px;
  border-top: 1px solid;

  .rc-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: " ";
  }
  .rc-pagination-total-text {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
    line-height: 26px;
    vertical-align: middle;
  }
  .rc-pagination-item {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    margin-right: 8px;
    line-height: 24px;
    font-family: Arial;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid transparent;
    border-radius: 24px;
    outline: 0;
    cursor: pointer;
    user-select: none;
  }
  .rc-pagination-item a {
    display: block;
    padding: 0 6px;
    margin-top: -1px;
    transition: all 0.3s;
  }
  .rc-pagination-item a:hover {
    text-decoration: none;
  }
  .rc-pagination-item-active {
    font-weight: 500;
  }

  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    outline: 0;
  }
  .rc-pagination-jump-prev button,
  .rc-pagination-jump-next button {
    border: 1px solid transparent;
    border-radius: 24px;
    line-height: 20px;
    cursor: pointer;
  }
  .rc-pagination-jump-prev button:after,
  .rc-pagination-jump-next button:after {
    display: block;
    content: "•••";
  }
  .rc-pagination-prev,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    margin-right: 8px;
  }
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    font-family: Arial;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  .rc-pagination-prev,
  .rc-pagination-next {
    border: 1px solid transparent;
    outline: 0;
  }
  .rc-pagination-prev button,
  .rc-pagination-next button {
    cursor: pointer;
    user-select: none;
  }
  .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-next .rc-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 22px;
    margin-top: -3px;
    text-align: center;
    background: transparent;
    border: none;
    outline: none;
    transition: all 0.3s;
  }
  .rc-pagination-prev button:after {
    content: "‹";
    display: block;
  }
  .rc-pagination-next button:after {
    content: "›";
    display: block;
  }
  .rc-pagination-disabled.rc-pagination-prev,
  .rc-pagination-disabled.rc-pagination-next {
    border: 1px solid transparent;
  }
  .rc-pagination-disabled,
  .rc-pagination-disabled:hover,
  .rc-pagination-disabled:focus {
    cursor: not-allowed;
  }
  .rc-pagination-disabled .rc-pagination-item-link,
  .rc-pagination-disabled:hover .rc-pagination-item-link,
  .rc-pagination-disabled:focus .rc-pagination-item-link {
    cursor: not-allowed;
  }
  .rc-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .rc-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media all and (-ms-high-contrast: none) {
    .rc-pagination-options *::-ms-backdrop,
    .rc-pagination-options {
      vertical-align: top;
    }
  }
  .rc-pagination-options-size-changer.rc-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }
  .rc-pagination-options-quick-jumper {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
  }
  .rc-pagination-options-quick-jumper input {
    width: 50px;
    margin: 0 8px;
  }
  .rc-pagination-simple .rc-pagination-prev,
  .rc-pagination-simple .rc-pagination-next {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
    height: 24px;
    background-color: transparent;
  }
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;
  }
  .rc-pagination.rc-pagination-disabled {
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item {
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item a {
    background: transparent;
    border: none;
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
    border-color: transparent;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
    color: #ffffff;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
    opacity: 0;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
    opacity: 1;
  }
  @media only screen and (max-width: 992px) {
    .rc-pagination-item-after-jump-prev,
    .rc-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .rc-pagination-options {
      display: none;
    }
  }
`;

interface GridPaginationProps {
  current: number;
  total: number;
  perPage: number;
  disabled?: boolean;
  onChange: (page: number) => void;
}

function GridPagination(props: GridPaginationProps) {
  return (
    <StyledPagination
      current={props.current}
      disabled={props.disabled}
      locale={locale}
      onChange={props.onChange}
      pageSize={props.perPage}
      total={props.total}
      className={`${CLASS_NAMES.BORDER_TOP} ${CLASS_NAMES.PAGINATOR}`}
    />
  );
}

export default GridPagination;
