import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";

const propertyPaneConfig: PropertyPaneConfig[] = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "title",
        label: "Header",
        controlType: "INPUT_TEXT",
        inputType: "text",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "Label",
        propertyCategory: PropsPanelCategory.Content,
      },
      ...typographyProperties({
        defaultVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
        textStyleParentDottedPath: "headerProps",
        propertyNameForHumans: "Header",
        hiddenIfPropertyNameIsNullOrFalse: "title",
      }),
      {
        helpText: "Takes in an array of objects to display items in the list.",
        propertyName: "gridData",
        label: "Data",
        controlType: "INPUT_TEXT",
        placeholderText: 'Enter [{ "col1": "val1" }]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.TABLE_DATA,
        propertyCategory: PropsPanelCategory.Content,
      },
      {
        propertyName: "columnCount",
        label: "No. columns",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "1",
            value: 1,
          },
          {
            label: "2",
            value: 2,
          },
          {
            label: "3",
            value: 3,
          },
          {
            label: "4",
            value: 4,
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.NUMBER,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        helpText: "Selects the default selected cell",
        propertyName: "defaultSelectedCell",
        label: "Default selected cell",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter cell index",
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        propertyName: "isSearchable",
        label: "Search",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
        propertyName: "animateLoading",
        label: "Loading animation",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      ...visibleProperties({ useJsExpr: false }),
      {
        propertyName: "gridCellScrollable",
        helpText: "Controls the scrollability of each grid cell",
        label: "Scroll cell contents",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Layout,
      },
    ],
  },
  sizeSection({ heightSupportsFitContent: true }),
  {
    sectionName: "Actions",
    sectionCategory: PropsPanelCategory.EventHandlers,
    children: [
      getPopoverConfig(
        "onCellClicked",
        "Triggers an action when a grid item is clicked",
      ),
    ],
  },
];

export { propertyPaneConfig as default };
