import moment from "moment";
import React from "react";
import shallowEqual from "shallowequal";
import styled from "styled-components";
import { TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import SearchComponent from "../Shared/SearchComponent";
import { useTypographyStyling } from "../typographyHooks";
import Chat from "./Chat";
import ChatInput from "./ChatInput";
import ChatLoading from "./ChatLoading";
import { MIN_CHAT_INPUT_HEIGHT, UserMessageObject } from "./Constants";

interface ChatComponentProps {
  messages: Array<Record<string, any>>;
  placeholderText?: string;
  header?: string;
  avatarField?: string;
  timestampField?: string;
  displayNameField: string;
  timestampFormat?: string;
  messageField: string;
  onMessageSend: (
    message: UserMessageObject,
    callbackFn: (success: boolean) => void,
  ) => void;
  enableImages: boolean;
  enableEnterToSend: boolean;
  enableSearch: boolean;
  enableEmojis: boolean;
  pendingMessages?: Record<string, UserMessageObject>;
  showPendingState?: boolean;
  pendingStateText?: string;
  pendingStateTimeout?: number;
  onMessageChange: (message: UserMessageObject) => void;
  isLoading: boolean;
  width: number;
  isDisabled: boolean;
  headerProps?: {
    textStyle: TextStyleWithVariant;
  };
}

interface ChatComponentWithStyle extends ChatComponentProps {
  headerStyle: React.CSSProperties;
  headerClassName: string;
}

interface ChatComponentState {
  searchText: string;
  pendingStateActive: boolean;
  chatInputHeight: number;
}

const ChatWrapper = styled.div<{
  $headerShown: boolean;
  $chatInputHeight: number;
}>`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 16px;
`;

const HeaderWrapper = styled.div`
  flex-shrink: 0;
  position: sticky;
  top: 0;
  padding: 0px 16px 0px 20px;
  display: flex;
  justify-content: space-between;
  .bp5-input-group {
    margin: 6px 0px;
  }
`;

const Header = styled.div`
  margin-left: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 1) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const FooterWrapper = styled.div`
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
`;

const PendingState = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.GREY_300};
  font-style: italic;
  margin-top: -10px;
  padding-left: 44px;
`;

class ChatComponent extends React.Component<
  ChatComponentWithStyle,
  ChatComponentState
> {
  chatContainerRef: React.RefObject<HTMLDivElement>;
  timeoutRef: undefined | ReturnType<typeof setTimeout>;

  constructor(props: ChatComponentWithStyle) {
    super(props);
    this.chatContainerRef = React.createRef<HTMLDivElement>();
    this.state = {
      searchText: "",
      pendingStateActive: false,
      chatInputHeight: MIN_CHAT_INPUT_HEIGHT,
    };
  }

  lastMessageIsLonger(
    prevMessages: Array<Record<string, any>>,
    messages: Array<Record<string, any>>,
  ) {
    if (
      !this.props.messageField ||
      !prevMessages ||
      !messages ||
      prevMessages.length !== messages.length
    )
      return false;

    const prevLastMessage = prevMessages[prevMessages.length - 1];
    const lastMessage = messages[messages.length - 1];
    if (!prevLastMessage || !lastMessage) return false;
    const prevMessageLength =
      prevLastMessage[this.props.messageField]?.length ?? 0;
    const messageLength = lastMessage[this.props.messageField]?.length ?? 0;
    return messageLength > prevMessageLength;
  }

  componentDidUpdate(
    prevProps: Readonly<ChatComponentWithStyle>,
    prevState: ChatComponentState,
  ) {
    if (
      this.props.messages.length !== prevProps.messages.length ||
      Object.keys(this.props.pendingMessages ?? {}).length > 0 ||
      (this.state.pendingStateActive && !prevState.pendingStateActive) ||
      this.lastMessageIsLonger(prevProps.messages, this.props.messages)
    ) {
      // scroll to bottom
      typeof this.chatContainerRef.current?.scrollTo === "function" &&
        this.chatContainerRef.current.scrollTo({
          top: this.chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
    } else if (Object.keys(this.props.pendingMessages ?? {}).length === 0) {
      // pending message cleared, reset pending state
      this.state.pendingStateActive &&
        this.setState({ pendingStateActive: false });
      this.timeoutRef && clearTimeout(this.timeoutRef);
      this.timeoutRef = undefined;
    }
  }

  componentWillUnmount(): void {
    this.timeoutRef && clearTimeout(this.timeoutRef);
  }

  shouldComponentUpdate(
    prevProps: Readonly<ChatComponentWithStyle>,
    prevState: Readonly<ChatComponentState>,
  ) {
    return (
      !shallowEqual(prevProps, this.props) ||
      !shallowEqual(prevState, this.state)
    );
  }

  getFormattedTime = (message: Record<string, string>) => {
    if (!this.props.timestampField || !message[this.props.timestampField]) {
      return "";
    }
    if (this.props.timestampFormat) {
      return moment(message[this.props.timestampField]).format(
        this.props.timestampFormat,
      );
    }
    return message[this.props.timestampField];
  };

  handleSearch = (searchText: string) => {
    this.setState({ searchText });
  };

  onMessageSend = (
    message: UserMessageObject,
    callbackFn: (success: boolean) => void,
  ) => {
    this.props.onMessageSend(message, callbackFn);
    if (this.props.showPendingState) {
      const timeout = this.props.pendingStateTimeout ?? 0;
      if (!this.timeoutRef) {
        this.timeoutRef = setTimeout(() => {
          this.setState({ pendingStateActive: true });
        }, timeout);
      }
    }
  };

  onHeightChange = (height: number) => {
    this.setState({ chatInputHeight: height });
  };

  render() {
    const headerShown = this.props.header || this.props.enableSearch;

    return (
      <div
        className={CLASS_NAMES.DEFAULT_CONTAINER}
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {headerShown && (
          <HeaderWrapper className={CLASS_NAMES.BORDER_BOTTOM}>
            <Header
              className={this.props.headerClassName}
              style={this.props.headerStyle}
            >
              {this.props.header}
            </Header>
            {this.props.enableSearch && (
              <SearchComponent
                value={this.state.searchText}
                onSearch={this.handleSearch}
                placeholder="Search"
              />
            )}
          </HeaderWrapper>
        )}
        {this.props.isLoading ? (
          <ChatLoading width={this.props.width} />
        ) : (
          <ChatWrapper
            ref={this.chatContainerRef}
            $headerShown={Boolean(headerShown)}
            $chatInputHeight={this.state.chatInputHeight}
          >
            {this.props.messages
              .filter((msg) => {
                if (!msg) return false;
                if (
                  !msg[this.props.messageField] ||
                  typeof msg[this.props.messageField] !== "string"
                ) {
                  return !this.state.searchText;
                }
                const name =
                  typeof msg[this.props.displayNameField] === "string"
                    ? msg[this.props.displayNameField].toLowerCase()
                    : "";
                const message =
                  typeof msg[this.props.messageField] === "string"
                    ? msg[this.props.messageField].toLowerCase()
                    : "";

                const searchText = this.state.searchText.toLowerCase();

                return (
                  message.includes(searchText) || name.includes(searchText)
                );
              })
              .map((message, index) => {
                return (
                  <Chat
                    key={index}
                    message={message[this.props.messageField]}
                    avatar={
                      this.props.avatarField && message[this.props.avatarField]
                    }
                    displayName={message[this.props.displayNameField]}
                    formattedTimestamp={this.getFormattedTime(message)}
                  />
                );
              })}
            {this.props.pendingMessages &&
              Object.keys(this.props.pendingMessages).length > 0 && (
                <>
                  {Object.keys(this.props.pendingMessages)
                    .sort()
                    .map((msgKey) => {
                      if (!this.props.pendingMessages) return null;
                      const message = this.props.pendingMessages[msgKey];
                      return (
                        <Chat
                          message={message.richText}
                          displayName={"You"}
                          isPending={true}
                          key={msgKey}
                        />
                      );
                    })}
                  {this.props.showPendingState &&
                    this.state.pendingStateActive && (
                      <PendingState data-test="chat-pending-text">
                        {this.props.pendingStateText}
                      </PendingState>
                    )}
                </>
              )}
          </ChatWrapper>
        )}
        {/* We still want to mount these components during loading, so that the tinyMCE script for the chat input loads */}
        <FooterWrapper style={this.props.isLoading ? { display: "none" } : {}}>
          <ChatInput
            onMessageSend={this.onMessageSend}
            enableImages={this.props.enableImages}
            enableEmojis={this.props.enableEmojis}
            enableEnterToSend={this.props.enableEnterToSend}
            placeholderText={this.props.placeholderText}
            onHeightChange={this.onHeightChange}
            height={this.state.chatInputHeight}
            onMessageChange={this.props.onMessageChange}
            isDisabled={this.props.isDisabled}
          />
        </FooterWrapper>
      </div>
    );
  }
}

const ChatComponentWithStyleProperties = (props: ChatComponentProps) => {
  const headerProps = useTypographyStyling({
    textStyle: props.headerProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
  });
  const headerClassName = getTextCssClassFromVariant(
    headerProps.textStyleVariant,
  );
  return (
    <ChatComponent
      {...props}
      headerStyle={headerProps.style ?? {}}
      headerClassName={headerClassName}
    />
  );
};

export default ChatComponentWithStyleProperties;
