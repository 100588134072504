import { Button, InputNumber, InputNumberProps, Tooltip } from "antd";
import React, { useEffect } from "react";
import { ReactComponent as UndoIcon } from "assets/icons/common/undo.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const inputStyle = styleAsClass`
  .ant-input-number {
    border-right: 0;

    &:focus, &:active {
        box-shadow: none;
        border-color: ${colors.ACCENT_BLUE_500};
    }
    &.ant-input-number-focused {
        border-color: ${colors.ACCENT_BLUE_500};
        box-shadow: none;
    }
    & input {
      font-size: 12px;
    }
  }
  .ant-input-number-group-addon {
    font-size: 12px;
    background: transparent;
    transition: all 0.2s;
    color: transparent;
    padding: 0px 8px 0px 2px;
  }
  &:hover {
    .ant-input-number {
        border-color: ${colors.ACCENT_BLUE_500};
    }
    .ant-input-number-group-addon {
        color: ${colors.GREY_300};
        border-color: ${colors.ACCENT_BLUE_500};
    }
  }
  .ant-input-number:focus, .ant-input-number-focused + .ant-input-number-group-addon {
        color: ${colors.GREY_300};
        border-color: ${colors.ACCENT_BLUE_500};
    }

  .px-addon {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-end;
  }
`;

export const PixelInput = (
  props: InputNumberProps<number> & {
    onResetToDefault?: () => void;
    showResetToDefault?: boolean;
  },
) => {
  const { onResetToDefault, showResetToDefault, onBlur, ...rest } = props;

  useEffect(() => {
    // call onBlur when component unmounts to flush changes
    return () => {
      onBlur?.(null as any);
    };
  }, [onBlur]);

  return (
    <InputNumber
      {...rest}
      onBlur={onBlur}
      precision={0}
      addonAfter={
        <div className="px-addon">
          <span>px</span>
          {onResetToDefault && (
            <Tooltip title="Reset to default" placement="topLeft">
              <Button
                style={{
                  padding: "7px 0px",
                  cursor: "pointer",
                  color: colors.GREY_300,
                  width: showResetToDefault ? 18 : 0,
                  opacity: showResetToDefault ? 1 : 0,
                  animation: showResetToDefault
                    ? "width 0.3s ease-out"
                    : "width 0.3s ease-in",
                  // subtle x translation to make the icon look like it's overlapping
                  transform: showResetToDefault
                    ? "translateX(0)"
                    : "translateX(-9px)",
                }}
                type={"text"}
                onClick={(e) => {
                  e.preventDefault();
                  onResetToDefault();
                }}
                disabled={props.disabled}
              >
                <UndoIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      }
      controls={false}
      className={`${inputStyle} ${props.className}`}
    />
  );
};
