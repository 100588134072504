import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { useFeatureFlag } from "hooks/ui";
import { Layers } from "legacy/constants/Layers";
import {
  WidgetTypes,
  WIDGET_CAN_HAVE_CHILDREN,
} from "legacy/constants/WidgetConstants";
import {
  useWidgetSelection,
  useSelectWidget,
  useWidgetDragResize,
} from "legacy/hooks/dragResizeHooks";
import { useOpenContextMenuForWidget } from "legacy/pages/Editor/WidgetContextMenu";
import { selectIsDragging } from "legacy/selectors/dndSelectors";
import { getEditorReadOnly } from "legacy/selectors/editorSelectors";
import {
  getIsMultipleWidgetsSelected,
  getIsWidgetFocused,
  getIsWidgetSelected,
  getWidget,
} from "legacy/selectors/sagaSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import { useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import { type AppState } from "store/types";
import { type WidgetPropsRuntime } from "../BaseWidget";
import { childStackItemElementId, isStackLayout } from "../StackLayout/utils";

const DraggableWrapper = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  height: 100%;
  user-select: none;
`;

// Widget Boundaries which is shown to indicate the boundaries of the widget
const WidgetBoundaries = styled.div`
  z-index: ${Layers.selectedWrapper};
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px dashed
    ${({ theme }) =>
      tinycolor(theme.colors.ACCENT_BLUE_500).setAlpha(0.5).toRgbString()};
  pointer-events: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0;
`;

const isSelectToDragType = (type?: string) => {
  return (
    type === WidgetTypes.CONTAINER_WIDGET ||
    type === WidgetTypes.TABS_WIDGET ||
    type === WidgetTypes.FORM_WIDGET
  );
};

/**
 * can drag helper function for react-dnd hook
 *
 * @param isResizing
 * @param isDraggingDisabled
 * @param props
 * @returns
 */
const canDrag = (
  isResizing: boolean,
  isDraggingDisabled: boolean,
  isDragSelecting: boolean,
  props: any,
) => {
  // we want to allow drag-to-select for inner widgets of a container if its not selected itself, otherwise we can drag the container
  if (isSelectToDragType(props.type) && isDragSelecting) {
    return false;
  }

  return (
    !isResizing &&
    !isDraggingDisabled &&
    !props.dragDisabled &&
    !isDragSelecting
  );
};

const DraggableComponent = (props: WidgetPropsRuntime) => {
  // Dispatch hook handy to set a widget as focused/selected
  const { selectWidgets, focusWidget, unfocusWidget } = useWidgetSelection();
  const selectWidget = useSelectWidget(props);

  const enableGrouping = useFeatureFlag(Flag.LAYOUTS_ENABLE_GROUPING);

  const canHaveChildren = (WIDGET_CAN_HAVE_CHILDREN as string[]).includes(
    props.type,
  );

  const isTable = props.type === "TABLE_WIDGET";
  const typeNeedsSelectToDrag = isSelectToDragType(props.type);

  // Dispatch hook handy to set any `DraggableComponent` as dragging/ not dragging
  // The value is boolean
  const { setIsDragging } = useWidgetDragResize();

  const isInStack = isStackLayout(props.parentLayout);

  const isSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, props.widgetId),
  );
  const isMultipleWidgetsSelected = useAppSelector(
    getIsMultipleWidgetsSelected,
  );

  // If cmd key is pressed to drag and select widgets inside containers
  const isDragSelectingRef = useRef(false);

  // We want to make sure we allow dragging by the widget name pill
  const isWidgetNameHoveredRef = useRef(false);

  // This state tells us which widget is focused
  // The value is the widgetId of the focused widget.
  const isFocused = useAppSelector((state) =>
    getIsWidgetFocused(state, props.widgetId),
  );

  const focusedWidgetId = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.focusedWidgetId,
  );
  const focusedWidget = useAppSelector((state) =>
    focusedWidgetId ? getWidget(state, focusedWidgetId) : null,
  );
  // This state tells us whether a `ResizableComponent` is resizing
  const isResizing = useSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isResizing,
  );

  // This state tells us whether a `DraggableComponent` is dragging
  const isDragging = useSelector(selectIsDragging);

  const editorReadyOnly = useSelector(getEditorReadOnly);

  // This state tells us to disable dragging,
  // This is usually true when widgets themselves implement drag/drop
  // This flag resolves conflicting drag/drop triggers.
  const isDraggingDisabled: boolean =
    useSelector(
      (state: AppState) => state.legacy.ui.widgetDragResize.isDraggingDisabled,
    ) || editorReadyOnly;

  const [, drag] = useDrag({
    item: props,
    collect: (monitor: DragSourceMonitor) => ({
      isWidgetBeingDragged: monitor.isDragging(),
    }),
    begin: () => {
      // When this draggable starts dragging
      // Tell the rest of the application that a widget has started dragging
      setIsDragging?.(true);

      // If the widget is not already selected, select it
      // if a user drags on an unselected widget, assume they just want to drag that widget
      // and clear the selected widgets
      if (!isSelected) {
        selectWidgets([props.widgetId], false);
      }

      AnalyticsUtil.logEvent("WIDGET_DRAG", {
        widgetName: props.widgetName,
        widgetType: props.type,
      });
    },
    end: (widget, monitor) => {
      // When this draggable is dropped, we try to open the propertypane
      // We pass the second parameter to make sure the previous toggle state (open/close)
      // of the property pane is taken into account.
      // See utils/hooks/dragResizeHooks.tsx
      const didDrop = monitor.didDrop();

      // Take this to the bottom of the stack. So that it runs last.
      // We do this because, we don't want erroneous mouse clicks to propagate.
      setIsDragging?.(false);

      AnalyticsUtil.logEvent("WIDGET_DROP", {
        widgetName: props.widgetName,
        widgetType: props.type,
        didDrop: didDrop,
      });
    },
    canDrag: () => {
      // Don't allow drag if we're resizing, the drag of `DraggableComponent` is disabled, or if we are
      // drag-selecting widgets inside a container
      return canDrag(
        isResizing,
        isDraggingDisabled,
        isDragSelectingRef.current,
        props,
      );
    },
  });

  const isCurrentWidgetDragging = isDragging && isSelected;

  // True when any widget is dragging or resizing, including this one
  const isResizingOrDragging = !!isResizing || !!isDragging;

  // If we're trying to select multiple elements, prevent the event from continuing
  // down the node tree so that clicks on buttons don't execute their triggers.
  // This is very helpful for doing things like selecting multiple buttons inside
  // a modal. Otherwise, clicking the "close" button closes the modal and the user
  // will lose their selection. It's also helpful on the root canvas if you want to click
  // buttons just to select them without running their triggers
  // But only do this for widgets that don't have children, otherwise selection of children
  // will be prevented
  // Allow shift+clicks on tables to enable multi-select of table rows
  const handleClickCapture = useCallback(
    (e?: React.MouseEvent) => {
      if (e?.shiftKey && !canHaveChildren && !isTable) {
        e.stopPropagation();
        // Now call regular handle click because the event won't make it there
        selectWidget(e);
      }
    },
    // Be very careful updating this dependency array. It can cause expensive renders
    [selectWidget, canHaveChildren, isTable],
  );

  const openContextMenuForWidget = useOpenContextMenuForWidget();
  const handleContextMenuClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // We want to allow widgets to stay selected when right-clicking somewhere else because it gives grouping options.
      if (!enableGrouping || !isMultipleWidgetsSelected) {
        selectWidget(e);
      }

      openContextMenuForWidget({
        widgetId: props.widgetId,
        clientX: e.clientX,
        clientY: e.clientY,
        isInsideIframe: true,
      });
    },
    [
      openContextMenuForWidget,
      selectWidget,
      isMultipleWidgetsSelected,
      enableGrouping,
      props.widgetId,
    ],
  );

  // When mouse is over this draggable, but we're using a ref to avoid rerenders
  const needsFocusRef = useRef(false);
  needsFocusRef.current =
    !isResizingOrDragging && !isFocused && !props.resizeDisabled;
  const handleMouseOver = useCallback(
    (e: any) => {
      if (needsFocusRef.current) {
        focusWidget(props.widgetId);
      }
      e.stopPropagation();
    },
    // Be very careful updating this dependency array. It can cause expensive renders
    [focusWidget, props.widgetId],
  );

  // When mouse is moves out of this draggable
  const needsUnfocusRef = useRef(false);
  needsUnfocusRef.current = !isResizingOrDragging && isFocused;
  const handleMouseLeave = useCallback(
    (e: any) => {
      if (
        needsUnfocusRef.current &&
        // Improves flickering state
        !e.relatedTarget?.classList?.contains?.("t--resize-handle") &&
        !e.relatedTarget?.classList?.contains?.("t--widget-name") &&
        !e.relatedTarget?.classList?.contains?.("t--widget-name-position") &&
        e.relatedTarget?.dataset?.maintainWidgetFocus !== "true"
      ) {
        unfocusWidget();
      }

      e.stopPropagation();
    },
    // Be very careful updating this dependency array. It can cause expensive renders
    [unfocusWidget],
  );

  const handlePointerDown = useCallback(
    (e?: React.MouseEvent) => {
      if (isWidgetNameHoveredRef.current) {
        isDragSelectingRef.current = false;
        return;
      }

      const isFocusedWidgetSelectedToDrag = isSelectToDragType(
        focusedWidget?.type,
      );

      if (
        e?.shiftKey ||
        e?.metaKey ||
        (typeNeedsSelectToDrag && !isSelected) ||
        (isFocusedWidgetSelectedToDrag &&
          focusedWidget?.widgetId !== props.widgetId)
      ) {
        isDragSelectingRef.current = true;
      }
      // Be very careful updating this dependency array. It can cause expensive renders
    },
    [typeNeedsSelectToDrag, isSelected, focusedWidget, props.widgetId],
  );

  const handlePointerUp = useCallback(
    (e?: React.MouseEvent) => {
      if (isDragSelectingRef.current) {
        isDragSelectingRef.current = false;
      }
    },
    // Be very careful updating this dependency array. It can cause expensive renders
    [isDragSelectingRef],
  );

  useEffect(() => {
    const onPointerOver = (e: PointerEvent) => {
      const node = e.relatedTarget as HTMLElement | undefined;
      if (!node) {
        return;
      }
      if (
        node.classList?.contains?.("t--widget-name") ||
        node.classList?.contains?.("t--widget-name-position") ||
        node?.querySelector(".t--widget-name") != null
      ) {
        isWidgetNameHoveredRef.current = true;
      } else {
        isWidgetNameHoveredRef.current = false;
      }
    };
    window.addEventListener("pointerover", onPointerOver, { passive: true });
    return () => {
      window.removeEventListener("pointerover", onPointerOver);
    };
  }, []);

  // Display this draggable based on the current drag state
  const childStyle: CSSProperties = useMemo(
    () => ({
      display: isCurrentWidgetDragging ? "none" : "block",
      cursor: editorReadyOnly ? "not-allowed" : undefined,
      pointerEvents: isCurrentWidgetDragging ? "none" : "auto",
      height: "100%",
    }),
    // Be very careful updating this dependency array. It can cause expensive renders
    [editorReadyOnly, isCurrentWidgetDragging],
  );

  const boundaryStyle = useMemo(
    () => ({
      opacity: isResizingOrDragging && !isSelected && !isInStack ? 1 : 0,
    }),
    [isResizingOrDragging, isSelected, isInStack],
  );
  const classNameForTesting = `t--draggable-${props.type
    .split("_")
    .join("")
    .toLowerCase()}`;

  const className = `${classNameForTesting}`;

  return (
    <DraggableWrapper
      data-test={`widget-${props.widgetName}`}
      data-is-resizing={Boolean(isResizing)}
      className={className}
      ref={drag}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={selectWidget}
      onClickCapture={handleClickCapture}
      onPointerDown={handlePointerDown}
      onContextMenu={handleContextMenuClick}
      onPointerUp={handlePointerUp}
      id={childStackItemElementId(props.widgetId)}
    >
      <div style={childStyle}>{props.children}</div>
      <WidgetBoundaries style={boundaryStyle} />
    </DraggableWrapper>
  );
};

DraggableComponent.displayName = "DraggableComponent";

export default DraggableComponent;
